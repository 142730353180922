import { get, post, del, put } from '@/services/http';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { FilterDimensionList } from '@/collections/filter_dimensions';
import { FilterDimensionModel } from '@/models/filter_dimension';
import { FilterColumnModel } from '@/models/filter_column';
import { FilterColumnList } from '@/collections/filter_columns';
import { ParamModel } from '@/models/param';
import { ParamList } from '@/collections/params';
import dateFormat from 'dateformat';
import { MetricModel } from '@/models/metric';
import { MetricList } from '@/collections/metrics';
import { FunnelEventModel } from '@/models/funnelEvent';

interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

export class CustomReportModel {
  public id: number;
  public name: string;
  public measure: MeasureModel;
  public dimension?: DimensionModel;
  public analyticsType?: AnalyticsTypeModel;
  public filterTimerange?: string[];
  public defaultRange?: string;
  public filterDimensions: FilterDimensionList;
  public filterColumns: FilterColumnList;
  public reportParams: ParamList;
  public createdAt?: string;
  public updatedAt?: string;
  public pivotOption: string = '';
  public reportColumns: any = [];
  public hasChart: boolean = true;
  public pageOptions: Option = {};
  public calculatedMetrics: MetricList;
  public productSegmentId: number;
  public reportQuery: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.measure = new MeasureModel();
    this.filterDimensions = new FilterDimensionList();
    this.filterColumns = new FilterColumnList();
    this.reportParams = new ParamList();
    this.pivotOption = '';
    this.reportColumns = [];
    this.hasChart = true;
    this.pageOptions = {};
    this.calculatedMetrics = new MetricList();
    this.productSegmentId = 0;
    this.reportQuery = '';
  }

  public async fetch() {
    const res: any = await get(`/custom_reports/${this.id}`);
    this.mapData(res.data);
  }

  public async save() {
    const data = {
      measure: this.measure.code,
      name: this.name,
      dimension: (this.dimension) ? this.dimension.code : null,
      analyticsType: (this.analyticsType) ? this.analyticsType.code : null,
      defaultRange: this.defaultRange,
      filterDimensions: this.exportFilterDimensions(),
      filterColumn: this.exportFilterColumns(),
      reportParams: this.exportReportParams(),
      pivotOption: this.pivotOption,
      reportColumns: this.reportColumns,
      startTime: this.filterTimerange ? this.filterTimerange[0] : null,
      endTime: this.filterTimerange ? this.filterTimerange[1] : null,
      options: {
        has_chart: this.hasChart,
        report_columns: this.reportColumns,
        page_options: this.pageOptions,
        calculated_metrics: this.calculatedMetrics,
        product_segment_id: this.productSegmentId,
        report_query: this.reportQuery,
      },
    };
    const res: any = await post('/custom_reports', data);
    this.mapData(res.data);
    try {
      const funnelEvent = new FunnelEventModel({
        name: 'SAVED_REPORT',
      });
      funnelEvent.create();
    } catch (error) {
      // skipped
    }
  }

  public getFormatedDate(date: string) {
    if (date) {
      return dateFormat(date, 'yyyy-mm-dd');
    }
    return '';
  }
  public async del() {
    const res: any = await del(`/custom_reports/${this.id}`);
  }

  public async updateOptions() {
    const res: any = await put(`/custom_reports/${this.id}`, {
      options: {
        has_chart: this.hasChart,
        report_columns: this.reportColumns,
        page_options: this.pageOptions,
        calculated_metrics: this.calculatedMetrics,
        product_segment_id: this.productSegmentId,
        report_query: this.reportQuery,
      },
    });
  }

  public async update() {
    const res: any = await put(`/custom_reports/${this.id}`, {
      measure: this.measure.code,
      name: this.name,
      dimension: (this.dimension) ? this.dimension.code : null,
      analyticsType: (this.analyticsType) ? this.analyticsType.code : null,
      defaultRange: this.defaultRange,
      filterDimensions: this.exportFilterDimensions(),
      filterColumn: this.exportFilterColumns(),
      reportParams: this.exportReportParams(),
      pivotOption: this.pivotOption,
      reportColumns: this.reportColumns,
      startTime: this.filterTimerange  ? this.filterTimerange[0] : null,
      endTime: this.filterTimerange ? this.filterTimerange[1] : null,
      options: {
        has_chart: this.hasChart,
        report_columns: this.reportColumns,
        page_options: this.pageOptions,
        calculated_metrics: this.calculatedMetrics,
        product_segment_id: this.productSegmentId,
        report_query: this.reportQuery,
      },
    });
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.measure = new MeasureModel({ code: data.measureCode });
    this.dimension = new DimensionModel({ code: data.dimensionCode });
    this.analyticsType = new AnalyticsTypeModel({ code: data.analyticsTypeCode });
    if (data.startTime && data.endTime) {
      this.filterTimerange = [
          this.getFormatedDate(data.startTime),
          this.getFormatedDate(data.endTime),
          data.defaultRange || 'custom',
        ];
    }
    if (data.filterDimensions) {
      this.importFilterDimensions(data.filterDimensions);
    }

    if (data.filterColumn) {
      this.importFilterColumns(data.filterColumn);
    }
    if (data.reportParams) {
      this.importReportParams(data.reportParams);
    }
    this.convertFilterDimensionToFilterColumn();
    this.defaultRange = data.defaultRange;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.pivotOption = data.pivotOption;
    if (data.options && data.options.report_columns) {
      this.reportColumns = data.options.report_columns;
    }
    if (data.options && data.options.has_chart) {
      this.hasChart = data.options.has_chart;
    }
    if (data.options && data.options.page_options) {
      this.pageOptions = data.options.page_options;
    }
    if (data.options && data.options.calculated_metrics) {
      this.importCalculatedMtrics(data.options.calculated_metrics);
    }
    if (data.options && data.options.product_segment_id) {
      this.productSegmentId = data.options.product_segment_id;
    }
    if (data.options && data.options.report_query) {
      this.reportQuery = data.options.report_query;
    }
  }

  public importCalculatedMtrics(data: any = []) {
    if (!data) {
      return;
    }
    if (!Array.isArray(data.items)) {
      return;
    }
    this.calculatedMetrics = new MetricList();
    for (const values of data.items) {
      const metric: MetricModel = new MetricModel();
      metric.mapData(values);
      this.calculatedMetrics.add(metric);
    }
  }

  private importFilterDimensions(data: any = []) {
    if (!data) {
      return;
    }
    this.filterDimensions = new FilterDimensionList();
    for (const values of data) {
      const filterDimension: FilterDimensionModel = new FilterDimensionModel();
      filterDimension.mapData(values);
      filterDimension.dimension = new DimensionModel();
      filterDimension.dimension.mapData(values.dimension);
      this.filterDimensions.add(filterDimension);
    }
  }

  private importReportParams(data: any = []) {
    if (!data) {
      return;
    }
    if (!Array.isArray(data)) {
      return;
    }
    this.reportParams = new ParamList();
    for (const values of data) {
      const param: ParamModel = new ParamModel();
      param.mapData(values);
      this.reportParams.add(param);
    }
  }

  private importFilterColumns(data: any = []) {
    if (!data) {
      return;
    }
    if (!Array.isArray(data)) {
      return;
    }
    this.filterColumns = new FilterColumnList();
    for (const values of data) {
      const filterColumn: FilterColumnModel = new FilterColumnModel();
      filterColumn.mapData(values);
      this.filterColumns.add(filterColumn);
    }
  }

  private convertFilterDimensionToFilterColumn() {
    if (!this.filterColumns) {
      this.filterColumns = new FilterColumnList();
    }
    for (const filterDimension of this.filterDimensions.items) {
      const filterColumn: FilterColumnModel = new FilterColumnModel();
      const data = {
        name: filterDimension.dimension.name,
        code: filterDimension.dimension.code,
        value: [filterDimension.name],
        operator: 'eq',
        dataType: 'text',
      };
      filterColumn.mapData(data);
      this.filterColumns.add(filterColumn);
    }
  }
  private exportFilterDimensions() {
    const data = [];
    for (const filterDimension of this.filterDimensions.items) {
      data.push({
        id: filterDimension.id,
        name: filterDimension.name,
        dimension: {
          name: filterDimension.dimension.name,
          luis_mapping: filterDimension.dimension.code,
        },
      });
    }
    return data;
  }

  private exportFilterColumns() {
    const data = [];
    for (const filterColumn of this.filterColumns.items) {
      data.push({
        name: filterColumn.name,
        code: filterColumn.code,
        value: filterColumn.value,
        operator: filterColumn.operator,
        dataType: filterColumn.dataType,
      });
    }
    return data;
  }

  private exportReportParams() {
    const data = [];
    for (const param of this.reportParams.items) {
      data.push({
        name: param.name,
        code: param.code,
        data_type: param.dataType,
        value_type: param.valueType,
        value: param.value,
        default: param.default,
      });
    }
    return data;
  }
}
