import { PropType } from 'vue'

export type ComparisonOperator = 
  '>' | '<' | '=' | '>=' | '<=' | 
  'BETWEEN' | 'CONTAINS' | 'REGEX' |
  'GREATER_THAN' | 'GREATER_AND_DIFF_PERCENT_GREATER' | 
  'GREATER_AND_DIFF_PERCENT_LESS' | 'PERCENTAGE_DIFFERENCE_BETWEEN' |
  'PERCENTAGE_DIFFERENCE_ABS_GREATER'

export type ColoringType = 'row' | 'cell' | 'report'

export class ColorRule {
  public id: string
  public columnCode: string
  public secondColumnCode?: string
  public value: number | [number, number]
  public operator: ComparisonOperator
  public color: string
  public coloringType: ColoringType
  public measure: string

  constructor(data: any = {}) {
    this.id = data.id;
    this.columnCode = data.columnCode;
    this.secondColumnCode = data.secondColumnCode;
    this.value = data.value;
    this.operator = data.operator;
    this.color = data.color;
    this.coloringType = data.coloringType;
    this.measure = data.measure;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.columnCode = data.columnCode;
    this.secondColumnCode = data.secondColumnCode;
    this.value = data.value;
    this.operator = data.operator;
    this.color = data.color;
    this.coloringType = data.coloringType;
    this.measure = data.measure;
  }

}

export const colorRuleProps = {
  rules: {
    type: Array as PropType<ColorRule[]>,
    required: true
  }
} 