
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ActionButton from "@/components/ActionButton.vue";
import {Fullscreen} from '@shopify/app-bridge/actions';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import {StoreModel} from '@/models/store';
import OnboardCompleteConfirm from "@/components/OnboardCompleteConfirm.vue";


@Component({
  components: {
    ActionButton,
    OnboardCompleteConfirm,
  },
})
export default class AppBanner extends Vue {
  @Prop({default: null})
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public isFullscreenMode: boolean = false;
  public showReportIssue: boolean = false;
  public dialogNotifyStoreDemo: boolean = false;
  public isDemoStore: boolean = false;
  public demoStoreName: string = 'assisty-demo.myshopify.com';

  public created() {
    this.showReportIssue = localStorage.getItem('showReportIssue') !== 'false';
    if (this.store.shopName === this.demoStoreName || window.location.hostname === 'demo.assisty.ai') {
      this.isDemoStore = true;
      this.dialogNotifyStoreDemo = true;
    }
  }

  public viewImportingStatusPage() {
    this.$router.push('/importing-status');
  }

  public openChat() {
    let message: string = 'I have encountered an issue while using the app and would like to report it.';
    // @ts-ignore: Unreachable code error
    this.$crisp.do('chat:open');
    // @ts-ignore: Unreachable code error
    this.$crisp.do('message:send', ['text', message]);
  }

  public doFullscreen() {
    const fullscreen = Fullscreen.create(this.app);
    fullscreen.dispatch(Fullscreen.Action.ENTER);
    this.isFullscreenMode = true;
    this.showReportIssue = false;
    localStorage.setItem('showReportIssue', 'false');
  }

  public exitFullscreen() {
    const fullscreen = Fullscreen.create(this.app);
    if (this.isFullscreenMode) {
      fullscreen.dispatch(Fullscreen.Action.EXIT);
      this.isFullscreenMode = false;
    }
    this.isFullscreenMode = false;
  }

  public get isEmbeddedMode() {
    return isShopifyEmbedded();
  }

}
