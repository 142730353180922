
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import { ReportMetadataList } from '@/collections/report_metadatas';
import { ReportMetadataModel } from '@/models/report_metadata';
import { TagList } from '@/collections/tags';
import { TagModel } from '@/models/tag';
import { TagTypeModel } from '@/models/tag_type';
import { TagTypeList } from '@/collections/tag_types';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ChatWithUs from '@/components/ChatWithUs.vue';
import { EventHandler } from '@/modules/events';
import { MeasureList } from '@/collections/measures';
import { StoreModel } from '@/models/store';
import { OpenaiModel } from '@/models/openai';
import { FunnelEventModel } from '@/models/funnelEvent';

interface ReportTerm {
  term: string;
  count: number;
  selected: boolean;
}

@Component({
  components: {
    HeaderTitle,
    ChatWithUs,
  },
})
export default class TemplateReports extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => new ReportMetadataList() })
  public templateReports!: ReportMetadataList;
  public reports: ReportMetadataList = new ReportMetadataList();
  @Prop({ default: () => new TagList() })
  public tags!: TagList;
  public selectedTag: TagModel = new TagModel();
  public tagType: TagTypeModel = new TagTypeModel();
  @Prop({ default: () => new TagTypeList() })
  public tagTypes!: TagTypeList;

  @Prop({ default: false })
  public loading!: boolean;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public hasSchemas!: boolean;
  @Prop({ default: 'available' })
  public isLockedReports!: string;
  public grouping: boolean = false;
  public index: number = 0;
  public search: string = '';
  public reportSearchResults: ReportMetadataList = new ReportMetadataList();
  public reportTerms: ReportTerm[] = [];
  public maxTermShow: number = 15;
  public openaiModel: OpenaiModel = new OpenaiModel();
  public thinking: boolean = false;
  public icon: string = 'mdi-message-text';
  public color: string = 'grey';
  public intervalId: any = null;
  public count: number = 0;
  public maxCount: number = 3;
  public intervalTime: number = 500;
  public iconText: string = '';
  public isSearch: boolean = false;
  public aiSearch: boolean = true;
  public countedReport: boolean = false;
  public isLoadingDashboardList: boolean = false;
  public hasPremiunReport: boolean = false;
  public firstTimeInit: boolean = true;
  public reportsByTag: any = [];
  public selectedCategory: number | null = null;
  public selectedChipCategory: number | null = null;
  private searchDebounceTimeout: any = null;
  public unfilteredSearchResults: ReportMetadataList = new ReportMetadataList();

  get categoryItems() {
    // Return only actual categories, without "All Categories"
    return this.tagTypes.items
      .filter(item => item.hasReport)
      .map(item => ({
        id: item.id,
        name: item.name
      }));
  }

  public onCategoryChange(categoryId: number) {
    // Clear search input and results when changing categories
    if (this.search) {
      // For specific category, filter the existing search results
      const category = this.tagTypes.items.find(item => item.id === categoryId);
      if (category) {
        this.tagType = category;
      }
    } else {
      // If no search is active, proceed with normal initialization
      this.init();
    }

    // Update the route based on category
    const category = this.tagTypes.items.find(item => item.id === categoryId);
    if (category) {
      this.$router.push(`/${this.hasPremiunReport ? 'premium_reports/true' : 'reports'}/${category.id}/${category.name.replace(/\s+/g, '-').toLowerCase()}`);
    }

    // Clear any existing search query params from URL
    if (this.$route.query.keyword) {
      this.$router.replace({ query: {} });
    }
  }

  @Watch('tagType', { immediate: true })
  onTagTypeChange(newVal: any) {
    this.selectedCategory = newVal.id || 0;
  }

  public async created() {
    this.initTagType();
    try {
      const funnelEvent = new FunnelEventModel({
        name: 'VIEWED_REPORT_LIBRARY',
      });
      funnelEvent.create();
    } catch (error) {
      // skipped
    }
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`view reports page`);
    } catch (e) {
      // ignore this
    }
  }

  public formatText(input: string) {
    // Keep the first character of the string in uppercase and the rest in lowercase
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
  }

  public initTagType() {
    for (const tagType of this.tagTypes.items) {
      if (!tagType.tags || tagType.tags.size() === 0) {
          for (const tag of this.tags.items) {
            if (tag.tagType.id === tagType.id) {
              tagType.tags.add(tag);
            }
        }
      }
    }
    this.selectedTag = new TagModel();
    const tagTypeId = Number(this.$route.params.type_id);
    if (tagTypeId === 0) {
      this.tagType = new TagTypeModel();
      this.tagType.id = 0;
      this.tagType.name = 'All Categories';
      this.tagType.tags = this.tags;
      return;
    }
    for (const item of this.tagTypes.items) {
      if (tagTypeId === item.id) {
        const tagType = new TagTypeModel();
        tagType.id = item.id;
        tagType.name = item.name;
        tagType.tags = item.tags;
        this.tagType = tagType;
      }
    }
  }

  public generateReportByTag(orginalTag: TagModel) {
    for (const report of this.reportSearchResults.items) {
      for (const tag of report.tags.items) {
        if (tag.id === orginalTag.id) {
          let exist = false;
          for (const item of this.reportsByTag) {
            if (item.tag.id === orginalTag.id) {
              exist = true;
              // Only add if less than 10 reports
              if (item.reports.length < 10) {
                item.reports.push(report);
              }
            }
          }
          if (!exist) {
            this.reportsByTag.push({ tag: orginalTag, reports: [report] });
          }
          break;
        }
      }
    }
  }

  public generateReportByCurrentTagType() {
    this.reportsByTag = [];
    for (const tag of this.tagType.tags.items) {
      this.generateReportByTag(tag);
    }
  }

  public selectTag(tag: TagModel) {
    this.search = '';
    if (tag.id === this.selectedTag.id) {
      this.selectedTag = new TagModel();
      this.init();
    } else {
      this.selectedTag = tag;
      this.startThinking();
      this.generateReportForSelectedTag();
      this.stopThinking();
    }
  }

  public generateReportForSelectedTag() {
    if (!this.selectedTag || !this.selectedTag.id) {
      return;
    }
    const newReports: ReportMetadataList = new ReportMetadataList();
    for (const report of this.templateReports.items) {
        for (const tag of report.tags.items) {
          if (tag.id === this.selectedTag.id) {
            if (this.search) {
              if (report.name.toLowerCase().includes(this.search.toLowerCase())) {
                newReports.add(report);
              }
            } else {
              newReports.add(report);
            }
            break;
          }
        }
      }
    this.reportSearchResults = newReports;
  }

  public startThinking() {
    this.thinking = true;
    this.intervalId = setInterval(() => {

      if (this.count >= this.maxCount) {
        this.iconText = '';
        this.count = 0;
      } else {
        this.iconText = this.iconText + '.';
        this.count = this.count + 1;
      }
    }, this.intervalTime);
    this.color = 'blue';

  }

  public stopThinking() {
    clearInterval(this.intervalId);
    this.iconText = '.';
    this.color = 'grey';
    this.count = 1;
    this.thinking = false;
  }

  public async searchReport(replaceUrl: boolean = true) {
    // Don't search if input is empty
    if (!this.search || this.search.trim() === '') {
      return;
    }

    // Clear any existing timeout
    if (this.searchDebounceTimeout) {
      clearTimeout(this.searchDebounceTimeout);
    }

    // Start the loading state
    this.startThinking();
    
    // Keep current results visible while searching
    const currentResults = this.reportSearchResults;
    
    // Debounce the search
    this.searchDebounceTimeout = setTimeout(async () => {
      try {
        const currentKeyword = this.$route.query.keyword;
        if (replaceUrl && this.search !== currentKeyword && this.search !== undefined && this.search !== null) {
          this.$router.replace({ query: { keyword: this.search } });
        } else if (currentKeyword && currentKeyword !== undefined && currentKeyword !== null) {
          this.search = currentKeyword + '';
        }
        
        try {
          // Try OpenAI search
          const openAIResults = await this.openaiModel.searchByOpenAI(this.search);
          
          // Update results if we got them
          if (openAIResults && openAIResults.size() > 0) {
            this.isSearch = true;
            this.reportSearchResults = openAIResults;
            // Create a single category for search results
            this.reportsByTag = [{
              tag: { name: `Search Results`, id: 'search' },
              reports: openAIResults.items
            }];
          }
        } catch (error) {
          // Keep current results on error
          this.reportSearchResults = currentResults;
        }
      } finally {
        this.stopThinking();
      }
    }, 500);
  }

  public getTranslatedText(text: string, module: string) {
    if (this.$te(`${module}.${text}`)) {
      return this.$t(`${module}.${text}`);
    } else {
      return text;
    }
  }

  public getTranslatedReportName(reportName: string) {
    let name: string = reportName;
    if (this.$te(`report_name.${reportName}`)) {
      name = this.$t(`report_name.${reportName}`) + '';
    } else {
      name = reportName;
    }
    //low case name
    name = name.toLowerCase();
    //make first letter upper case
    name = name.charAt(0).toUpperCase() + name.slice(1);
    return name;
  }

  public getTranslatedTagName(tagName: string) {
    if (this.$te(`report_tags.${tagName}`)) {
      return this.$t(`report_tags.${tagName}`);
    } else {
      return tagName;
    }
  }

  public get pageTitle() {
    if (this.tagType.name && this.tagType.name !== undefined) {
      return this.formatText(this.tagType.name);
    } else {
      return '';
    }
  }

  public init() {
    this.startThinking();
    this.isSearch = false;
    this.reportSearchResults = this.templateReports

    if (this.selectedTag && this.selectedTag.id) {
      this.generateReportForSelectedTag();
    } else {
      this.generateReportByCurrentTagType();
    }
    this.stopThinking();
  }

  public viewReport(item: ReportMetadataModel, tag: TagModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track('click on report', {
        tagId: tag.id,
        templateReportId: item.id,
        tagName: tag.name,
        templateReportName: item.name,
      });
    } catch (e) {
      // ignore this
    }
    this.$router.push(`/reports/${this.tagType.id}/${this.tagType.name.replace(/\s+/g, '-').toLowerCase()}/${item.id}`);
  }

  public getReportLink(item: ReportMetadataModel) {
    return (`/reports/${this.tagType.id}/${this.tagType.name.replace(/\s+/g, '-').toLowerCase()}/${item.id}`);
  }

  public goToRoadmap() {
    window.open('https://roadmap.assisty.ai', '_blank');
  }

  private sort(reports: ReportMetadataList, tag: TagModel) {
    const compare = (a: ReportMetadataModel, b: ReportMetadataModel) => {
      if (!a.sortOrder || !a.sortOrder.tag) {
        return 1;
      }
      if (!b.sortOrder || !b.sortOrder.tag) {
        return -1;
      }
      if (a.sortOrder.tag[tag.id] < b.sortOrder.tag[tag.id]) {
        return -1;
      }
      if (a.sortOrder.tag[tag.id] > b.sortOrder.tag[tag.id]) {
        return 1;
      }
      return 0;
    };
    reports.items = reports.items.sort(compare);
    return reports;
  }

  private sortReportTerm(reportTerms: ReportTerm[]) {
    const compare = (a: ReportTerm, b: ReportTerm) => {
      return b.count - a.count;
    };
    reportTerms = reportTerms.sort(compare);
    return reportTerms;
  }

  @Watch('$route.params.type_id', { immediate: true, deep: true })
  private async tagTypeChanged(newVal: any) {
    if (this.firstTimeInit) {
      return;
    }
    if (this.templateReports.size() === 0) {
      return;
    }
    this.initTagType();
    // Only call init() if there's no active search
    if (!this.search || this.search.trim() === '') {
      await this.init();
    }
  }

  @Watch('$route.path', { immediate: true, deep: true  })
  private async pathChanged(newVal: any) {
    if (['/reports', '/'].includes(newVal) && this.tagTypes.size() > 0) {
      this.$router.push(`/reports/0}/all-categories`);
    }
  }

  @Watch('templateReports', { immediate: true, deep: true  })
  private async templateReportsChanged(newVal: any) {
    if (!this.templateReports || this.templateReports.items.length === 0) {
      return;
    }
    if (this.firstTimeInit) {
      this.firstTimeInit = false;
    }
    // init the unfilteredSearchResults here
    this.unfilteredSearchResults = newVal;
    this.initTagType();
    await this.init();
    const search = this.$route.query.keyword;
    if (search && search !== undefined) {
      this.search = search + '';
      await this.searchReport(false);
    }
  }

  // New computed property to chunk reports into columns
  get chunkedReports() {
    if (!this.reportsByTag) return [];
    const reports = [...this.reportsByTag];
    const mid = Math.ceil(reports.length / 2);
    return [reports.slice(0, mid), reports.slice(mid)];
  }

  // Method to get appropriate icon for each category
  public getCategoryIcon(categoryName: string) {
    const name = categoryName.toLowerCase();
    const iconMap: { [key: string]: string } = {
      'most popular reports': 'mdi-star',
      'sales performance': 'mdi-chart-line',
      'product analytics': 'mdi-chart-box',
      'demand forecasting': 'mdi-chart-timeline-variant',
      'inventory replenishment': 'mdi-package-variant-plus',
      'inventory optimization': 'mdi-package-variant-closed',
      'order management': 'mdi-clipboard-list',
      'customer insights': 'mdi-account-group',
      'finance data': 'mdi-currency-usd',
      'by business entities': 'mdi-domain',
      'by business use-cases': 'mdi-briefcase'
    };

    return iconMap[name] || 'mdi-file-chart-outline';
  }

  // Method to clear search and reset results
  public clearSearch(): void {
    // Start loading state
    this.startThinking();

    // Use setTimeout to avoid UI blocking
    setTimeout(() => {
      try {
        this.search = '';
        this.isSearch = false;
        
        // Reset category to previous state if needed
        const currentTagTypeId = Number(this.$route.params.type_id);
        if (currentTagTypeId !== this.selectedCategory) {
          this.selectedCategory = currentTagTypeId;
          this.initTagType();
        }

        // Clear search query from URL without waiting for navigation
        if (this.$route.query.keyword) {
          this.$router.replace({ query: {} }).catch(() => {});
        }

        // Reset results
        this.reportSearchResults = this.templateReports;
      } finally {
        this.stopThinking();
      }
    }, 0);
  }

  // Method to scroll to a specific category section
  public scrollToCategory(categoryId: number): void {
    const element = document.getElementById(`category-${categoryId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  // Method to get a description for a report
  public getReportDescription(report: ReportMetadataModel): string {
    if (report.instruction) {
      let description = report.instruction;
      let lowerCaseDescription = description.toLowerCase();
      const prefixes = [
        'report provides',
        'this report provides',
        'this report shows',
        'this report includes',
        'this report highlights',
        'report shows',
        'report includes',
        'report highlights',
        'is a report that provides',
        'is a report that shows',
        'is a report that includes',
        'is a report that highlights'
      ];
      
      // Check each prefix individually to avoid partial matches
      for (const prefix of prefixes) {
        const regex = new RegExp(`^${prefix}|^.*?${prefix}`, 'i');
        if (regex.test(lowerCaseDescription)) {
          description = description.replace(regex, '');
          break;
        }
      }
      //remove leading and trailing spaces
      description = description.trim();
      //make first letter uppercase
      description = description.charAt(0).toUpperCase() + description.slice(1);
      let shortDescription = description.length > 100 ? description.substring(0, 100) + '...' : description;
      return shortDescription;
    }
    return `Interactive report with real-time data visualization`;
  }
  // Method to get icon for tag based on tag name keywords
  public getTagIcon(tag: TagModel): string {
    const name = tag?.name?.toLowerCase() || '';
    const iconMap: { [key: string]: string } = {
      'sales': 'mdi-chart-line',
      'revenue': 'mdi-cash',
      'profit': 'mdi-cash-plus',
      'inventory': 'mdi-package-variant',
      'stock': 'mdi-package-variant-closed',
      'customer': 'mdi-account-group',
      'order': 'mdi-clipboard-list',
      'product': 'mdi-cube',
      'forecast': 'mdi-chart-timeline',
      'trend': 'mdi-trending-up',
      'performance': 'mdi-chart-bar',
      'analytics': 'mdi-chart-box',
      'dashboard': 'mdi-view-dashboard',
      'summary': 'mdi-file-document-outline',
      'overview': 'mdi-eye-outline',
      'payment': 'mdi-credit-card',
      'daily': 'mdi-calendar-today',
      'weekly': 'mdi-calendar-week',
      'monthly': 'mdi-calendar-month',
      'vendor': 'mdi-store',
      'store': 'mdi-store',
      'location': 'mdi-map-marker',
      'popular': 'mdi-star',
      'top': 'mdi-podium',
      'business': 'mdi-domain',
      'finance': 'mdi-currency-usd',
      'optimization': 'mdi-tune',
      'management': 'mdi-cog',
      'insights': 'mdi-lightbulb',
      'entities': 'mdi-sitemap',
      'use-cases': 'mdi-briefcase'
    };

    // Check if tag name contains any of the keywords
    for (const [keyword, icon] of Object.entries(iconMap)) {
      if (name.includes(keyword)) {
        return icon;
      }
    }

    return 'mdi-tag'; // Default icon if no keyword matches
  }


  // Method to get icon for report based on report name keywords
  public getReportIcon(reportName: string): string {
    const name = reportName.toLowerCase();
    const iconMap: { [key: string]: string } = {
      'sales': 'mdi-chart-line',
      'revenue': 'mdi-cash',
      'profit': 'mdi-cash-plus',
      'inventory': 'mdi-package-variant',
      'stock': 'mdi-package-variant-closed',
      'customer': 'mdi-account-group',
      'order': 'mdi-clipboard-list',
      'product': 'mdi-cube',
      'forecast': 'mdi-chart-timeline',
      'trend': 'mdi-trending-up',
      'performance': 'mdi-chart-bar',
      'analytics': 'mdi-chart-box',
      'dashboard': 'mdi-view-dashboard',
      'summary': 'mdi-file-document-outline',
      'overview': 'mdi-eye-outline',
      'payment': 'mdi-credit-card',
      'daily': 'mdi-calendar-today',
      'weekly': 'mdi-calendar-week',
      'monthly': 'mdi-calendar-month',
      'vendor': 'mdi-store',
      'variant': 'mdi-cube-outline',
      'store location': 'mdi-map-marker',
      'average': 'mdi-calculator',
      'top': 'mdi-podium',
      'replenishment': 'mdi-package-variant-plus',
      'forecasting': 'mdi-chart-timeline-variant',
      'fulfillment': 'mdi-truck-delivery',
      'export': 'mdi-export',
      'collection': 'mdi-folder-multiple',
      'sell-through': 'mdi-chart-areaspline',
      'inventory to sales': 'mdi-chart-donut',
      'lost revenue': 'mdi-cash-remove',
      'out of stock': 'mdi-package-variant-remove',
      'excess stock': 'mdi-package-variant-plus',
      'turnover': 'mdi-refresh',
      'aging': 'mdi-clock-outline',
      'coverage': 'mdi-shield-check'
    };

    // Check if report name contains any of the keywords
    for (const [keyword, icon] of Object.entries(iconMap)) {
      if (name.includes(keyword)) {
        return icon;
      }
    }

    return 'mdi-file-chart-outline'; // Default icon if no keyword matches
  }

  // Add a computed property for the category select disabled state
  get isCategorySelectDisabled(): boolean {
    return !!this.search && this.search.trim() !== '';
  }
}
