
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Alert extends Vue {
  @Prop({ default: 'info' }) public type!: string;
  @Prop({ default: '' }) public title!: string;
  @Prop({ default: '' }) public content!: string;
  @Prop({ default: true }) public showAlert!: boolean;
  @Prop({ default: '' }) public actionTitle!: string;
  @Prop({ default: '' }) public secondaryActionTitle!: string;
  @Prop({ default: '' }) public actionLink!: string;
  @Prop({ default: true }) public canDimiss!: boolean;
  @Prop({ default: '' }) public iconColor!: string;

  get hasActions(): boolean {
    return !!(this.actionTitle || this.secondaryActionTitle);
  }

  get getIcon(): string {
    const icons: { [key: string]: string } = {
      info: 'mdi-information-outline',
      update: 'mdi-update',
      success: 'mdi-check-circle-outline',
      warning: 'mdi-alert-outline',
      error: 'mdi-alert-octagon-outline'
    };
    return icons[this.type] || icons.info;
  }

  get getIconColor(): string {
    const colors: { [key: string]: string } = {
      info: 'primary',
      update: 'primary',
      success: 'success',
      warning: 'warning',
      error: 'error'
    };
    return colors[this.type] || colors.info;
  }

  get getActionColor(): string {
    return this.type === 'error' ? 'error' : 'primary';
  }

  public dismiss(): void {
    this.$emit('update:showAlert', false);
  }

  public actionClick(): void {
    if (!this.actionLink) {
      this.$emit('actionClick');
    }
  }

  public secondaryActionClick(): void {
    this.$emit('secondaryActionClick');
  }
}
