import { get } from '@/services/http';
import { ColorRule } from '@/models/color_rule';
import List from './list';

export class ColorRuleList extends List<ColorRule> {

  public mapData(data: any = []) {
    for (const s of data) { 
      const item: ColorRule = new ColorRule();
      item.mapData(s);
      this.add(item);
    }
  }

  public evaluateRow(row: any, measure: string): {color: string, coloringType: string} | null {
    for (const rule of this.items) {
      if (rule.measure !== measure || rule.coloringType !== 'row') {
        continue;
      }

      if (this.evaluateRule(rule, row)) {
        return {
          color: rule.color,
          coloringType: rule.coloringType,
        };
      }
    }
    return null;
  }

  private evaluateRule(rule: ColorRule, row: any): boolean {
    if (rule.secondColumnCode) {
      return this.evaluateMultiColumnRule(rule, row);
    }

    if (rule.coloringType === 'report') {
      return this.evaluateAllColumnsRule(rule, row);
    } else {
      return this.evaluateSingleColumn(rule, row);
    }
  }

  // write a function to parse value from string to number
  private parseValue(value: string | undefined): number {
    if (!value) {
      return NaN;
    }
    // remove commas, currency symbols, and trailing %
    value = value.replace(/,/g, '');    
    value = value.replace(/^[^\d.-]/, ''); // Remove leading non-digit characters except - and .
    value = value.replace(/%$/, ''); // Remove trailing % symbol
    return parseFloat(value);
  }

  private evaluateSingleColumn(rule: ColorRule, row: any): boolean {
    const value = this.parseValue(row[rule.columnCode as string]);
    if (isNaN(value)) {
      return false;
    }

    if (Array.isArray(rule.value)) {
      if (rule.operator === 'BETWEEN' || rule.operator === 'PERCENTAGE_DIFFERENCE_BETWEEN') {
        const [min, max] = rule.value;
        return value >= min && value <= max;
      }
      return false;
    }

    switch (rule.operator) {
      case '>':
        return value > rule.value;
      case '<':
        return value < rule.value;
      case '=':
        return value === rule.value;
      default:
        return false;
    }
  }

  private evaluateAllColumnsRule(rule: ColorRule, row: any): boolean {
    for (const value of Object.values(row)) {
      const numValue = this.parseValue(value as string);
      if (isNaN(numValue)) {
        continue;
      }

      if (Array.isArray(rule.value)) {
        if (rule.operator === 'BETWEEN' || rule.operator === 'PERCENTAGE_DIFFERENCE_BETWEEN') {
          const [min, max] = rule.value;
          if (numValue >= min && numValue <= max) {
            return true;
          }
        }
      } else {
        switch (rule.operator) {
          case '>':
            if (numValue > rule.value) return true;
            break;
          case '<':
            if (numValue < rule.value) {
              return true;
            }
            break;
          case '=':
            if (numValue === rule.value) {
              return true;
            }
            break;
        }
      }
    }
    return false;
  }

  public evaluateCell(row: any, columnCode: string, measure: string): string | null {
    const applicableRules = this.items.filter(rule => 
      rule.measure === measure && 
      (rule.coloringType === 'cell' || rule.coloringType === 'report')
    );

    if (!applicableRules.length) {
      return null;
    }

    const value = this.parseValue(row[columnCode]);
    if (isNaN(value)) {
      return null;
    }

    const matchingRule = applicableRules.find(rule => {
        const columnMatches = rule.coloringType === 'report' || rule.columnCode === columnCode;
        return columnMatches && this.evaluateValue(rule, value);
    });

    return matchingRule?.color ?? null;
  }

    private doesColumnMatch(rule: ColorRule, columnCode: string): boolean {
      if (rule.coloringType === 'report') {
        return true;
      }
      return rule.columnCode === columnCode;
    }

  private evaluateValue(rule: ColorRule, value: number): boolean {
    if (Array.isArray(rule.value)) {
      if (rule.operator === 'BETWEEN') {
        const [min, max] = rule.value;
        return value >= min && value <= max;
      }
      return false;
    }
    switch (rule.operator) {
      case '>':
        return value > rule.value;
      case '<':
        return value < rule.value;
      case '=':
        return value === rule.value;
      default:
        return false;
    }
  }

  private evaluateMultiColumnRule(rule: ColorRule, row: any): boolean {
    const columns = [rule.columnCode, rule.secondColumnCode as string];
    const EPSILON = 0.000001;
    
    switch (rule.operator) {
      case 'GREATER_THAN':
        const gtVal1 = this.parseValue(row[columns[0]]);
        const gtVal2 = this.parseValue(row[columns[1]]);
        if (isNaN(gtVal1) || isNaN(gtVal2)) {
          return false;
        }
        return gtVal1 > gtVal2;

      case 'GREATER_AND_DIFF_PERCENT_GREATER':
        const gpdgVal1 = this.parseValue(row[columns[0]]);
        const gpdgVal2 = this.parseValue(row[columns[1]]);
        if (isNaN(gpdgVal1) || isNaN(gpdgVal2)) {
          return false;
        }
        const gpdgDivisor = Math.abs(gpdgVal2) < EPSILON ? EPSILON : gpdgVal2;
        return gpdgVal1 > gpdgVal2 && 
               ((gpdgVal1 - gpdgVal2) / gpdgDivisor * 100) > (rule.value as number);

      case 'GREATER_AND_DIFF_PERCENT_LESS':
        const gpdlVal1 = this.parseValue(row[columns[0]]);
        const gpdlVal2 = this.parseValue(row[columns[1]]);
        if (isNaN(gpdlVal1) || isNaN(gpdlVal2)) {
          return false;
        }
        const gpdlDivisor = Math.abs(gpdlVal2) < EPSILON ? EPSILON : gpdlVal2;
        return gpdlVal1 > gpdlVal2 && 
               ((gpdlVal1 - gpdlVal2) / gpdlDivisor * 100) < (rule.value as number);

      case 'PERCENTAGE_DIFFERENCE_BETWEEN':
        const pdbVal1 = this.parseValue(row[columns[0]]);
        const pdbVal2 = this.parseValue(row[columns[1]]);
        if (isNaN(pdbVal1) || isNaN(pdbVal2)) {
          return false;
        }
        const pdbDivisor = Math.abs(pdbVal2) < EPSILON ? EPSILON : pdbVal2;
        const percentDiff = Math.abs((pdbVal1 - pdbVal2) / pdbDivisor * 100);
        const [min, max] = rule.value as number[];
        return percentDiff >= min && percentDiff <= max;

      case 'PERCENTAGE_DIFFERENCE_ABS_GREATER':
        const pdagVal1 = this.parseValue(row[columns[0]]);
        const pdagVal2 = this.parseValue(row[columns[1]]);
        if (isNaN(pdagVal1) || isNaN(pdagVal2)) {
          return false;
        }
        const pdagDivisor = Math.abs(pdagVal2) < EPSILON ? EPSILON : pdagVal2;
        const absDiff = Math.abs((pdagVal1 - pdagVal2) / pdagDivisor * 100);
        return absDiff > (rule.value as number);

      default:
        return false;
    }
  }

}
