import dateFormat from 'dateformat';
import md5 from 'md5';
import { get, getIdentityToken } from '@/services/http';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { ComparisonDateModel } from '@/models/comparison_date';
import { FilterDimensionList } from '@/collections/filter_dimensions';
import { FilterColumnList } from '@/collections/filter_columns';
import { Md5 } from 'ts-md5';
import axios from 'axios';
import { ParamList } from '@/collections/params';
import { MetricList } from '@/collections/metrics';
import { ProductSegmentModel } from '@/models/product_segment';
import { FunnelEventModel } from '@/models/funnelEvent';

interface Column {
  name: string;
  code: string;
  values: any[];
  originalValues: string[];
  dataType: string;
  filterable: boolean;
  sortable: boolean;
  luisMapping: string;
  comparison: any;
  description: string;
  colorIndex: boolean;
  autoSelect: boolean;
}

interface Cell {
  value: string; column: Column;
}

interface Row {
  items: Cell[];
}

interface Metric {
  mainMeasure: any;
  relatedMeasure: any;
  trend: string;
  color?: string;
}

export class ReportModel {
  public rows: Row[];
  public columns: Column[];
  public columnList!: Column[];
  public summary: string;
  public metric: Metric;
  public typeReport: string;
  public measure?: MeasureModel;
  public dimension?: DimensionModel;
  public comparisonDate?: ComparisonDateModel;
  public analyticsType?: AnalyticsTypeModel;
  public filterTimerange?: string[];
  public filterDimensions?: FilterDimensionList;
  public filterColumns?: FilterColumnList;
  public reportParams: ParamList = new ParamList();
  public reportColumns: any = [];
  public isDetail: boolean;
  public isMetric: boolean;
  public page!: number|string;
  public perPage!: number|string;
  public total!: number|string;
  public sortBy!: any;
  public sortDesc!: any;
  public source?: any;
  public sourceCount?: any;
  public loadingData: boolean = false;
  public loadingCount: boolean = false;
  public pivotOption: string = '';
  public downloadFileExt: string = 'csv';
  public calculatedMetrics?: MetricList;
  public productSegment?: ProductSegmentModel;
  public reportPath: string = '/questions/report';
  private token?: string;
  public isCounting: boolean = false;

  constructor() {
    this.rows = [];
    this.columns = [];
    this.columnList = [];
    this.summary = '';
    this.metric = { mainMeasure: null, relatedMeasure: null, trend: ''  };
    this.typeReport = 'SUMMARY';
    this.isDetail = false;
    this.isMetric = false;
    if (process.env.VUE_APP_ENVIRONMENT === 'production') {
      this.reportPath = '/data-api/questions/report';
    }
    this.token = getIdentityToken();
  }

  public async getReport(cache=true) {
    if (!this.measure || !this.measure.code) {
      return;
    }
    if (!this.dimension || !this.dimension.code) {
      return;
    }


    this.rows = [];
    this.columns = [];
    this.summary = '';
    try {
      if (this.typeReport === 'TABLE') {
        if (!this.page || !this.perPage) {
          return;
        }
      }

      const res: any = await this.fetchData(cache);
      switch (this.typeReport) {
        case 'METRIC': {
          this.metric = res.data[0];
          break;
        }
        default: {
          this.rows = res.data[0].rows;
          this.columns = res.data[0].columns;
          if (res.data[0].columns_list) {
            this.columnList = res.data[0].columns_list;
          }
          if (!this.total) {
            this.total = (this.rows.length === this.perPage) ? this.perPage * 2 : this.rows.length;
          }
          for (const col of this.columns) {
            if (col.dataType === 'percent') {
              col.originalValues = col.originalValues.map((v: any) => String(Number(v) * 100));
            }
            if (col.dataType === 'date') {
              col.values = col.values.map((v: any) => {
                try {
                  return dateFormat(v, 'mmm d, yyyy', true);
                } catch (error) {
                  return v;
                }
              });
            }
            if (col.dataType === 'decimal' || col.dataType === 'number') {
              col.values = col.values.map((v: any) => isNaN(v) ? (v === 'null' ? 0 : v) : Number(v).toLocaleString('en-US'));
            }
            if (col.dataType === 'currency') {
              if (col.values.length > 0) {
                if (col.values[0].includes('$')) {
                  // col.name = `${col.name} ($)`;
                  col.values = col.values.map((v: any) => isNaN(v.split('$')[1]) ? v.split('$')[1]
                  : Number(v.split('$')[1]).toLocaleString('en-US'));
                } else {
                  // get currency from store model
                  const currency = localStorage.getItem('currency') || 'USD';
                  //need to conver the value to number before converting to currency
                  col.values = col.originalValues.map((v: any) => isNaN(v) ? 0 : Number(v));
                  col.values = col.values.map((v: any) => v.toLocaleString('en-US', {
                    style: 'currency',
                    currency: currency,
                  }));
                  
                }
              }
            }
          }
          break;
        }
      }
    } catch (e: any) {
      throw(e);
      this.summary = '';
      this.metric = { mainMeasure: null, relatedMeasure: null, trend: ''  };
      this.rows = [];
      this.columns = [];
    }
  }

  public async getReportColumns() {
    try {
      if (this.typeReport !== 'TABLE') {
        return;
      }
      const res: any = await this.fetchData();
      return res.data[0].columns;
    } catch (e: any) {
      throw(e);
    }
  }

  public async fetchData(cache=true) {
    const params = this.generateParams();
    const hash = `cache_${md5(this.token)}_${md5(this.reportPath)}_${md5(JSON.stringify(params))}`;

    // force clear cache
    if (!cache) {
      localStorage.removeItem(hash);
    }

    this.loadingData = true;

    let data = localStorage.getItem(hash);
    if (!data) {
      // cancel previus requests
      if (this.source) {
        try {
          this.source.cancel();
          this.source = null;
        } catch (e) {
          // skipped
        }
      }
      

      const CancelToken = axios.CancelToken;
      this.source = CancelToken.source();
      data = await get(this.reportPath, params, this.source.token);
      localStorage.setItem(hash, JSON.stringify(data));
    } else {
      data = JSON.parse(data);
    }

    this.loadingData = false;
    return data;
  }

  public async fetchCount(cache=true) {
    this.loadingCount = true;
    const params = this.generateParams(true, true);

    const hash = `cache_${md5(this.token)}_${md5(this.reportPath)}_${md5(JSON.stringify(params))}`;
    if (!cache) {
      localStorage.removeItem(hash);
    }

    let data = localStorage.getItem(hash);
    if (!data) {
      if (this.sourceCount) {
        try {
          this.sourceCount.cancel();
          this.sourceCount = null;
        } catch (e) {
          // skipped
        }
      } 

      const CancelToken = axios.CancelToken;
      this.sourceCount = CancelToken.source();
      data = await get(this.reportPath, params, this.sourceCount.token);
      localStorage.setItem(hash, JSON.stringify(data));
    } else {
      data = JSON.parse(data);
    }
    this.loadingCount = false;
    return data;
}

  public async getCount(cache=true) {
    // if (this.typeReport === 'TABLE') {
    //   console.log('page=', this.page, 'perPage=', this.perPage);
    //   if (!this.page || !this.perPage) {
    //     return;
    //   }
    // }
    try {
      this.isCounting = true;
      const res: any = await this.fetchCount();
      this.total = (res.total) ? res.total : this.total;
    } catch (e: any) {
      // skipped
    }
    this.isCounting = false;
  }

  public async download(emails: string[] = [], isDownloadFull: boolean = true, selectedFolder: string = '', downloadName: string = '') {
    const params = this.generateParams(!isDownloadFull);
    params.emails = emails.join(',');
    params.download = true;
    params.isRaw = false;
    params.channel = 'websocket';
    params.download_file_ext = this.downloadFileExt;
    params.selectedFolder = selectedFolder;
    params.downloadName = downloadName;

    const res: any = await get(this.reportPath, params);
    try {
      const funnelEvent = new FunnelEventModel({
        name: 'DOWNLOAD_REPORT',
      });
      funnelEvent.create();
    } catch (error) {
      // skipped
    }
  }

  public generateSortByParam() {
    if (!this.sortBy || this.sortBy.length === 0) {
      return [];
    }
    const sortBy: any = [];
    for (let index = 0; index < this.sortBy.length; index++) {
      sortBy.push(
        {
          key: this.sortBy[index],
          order: this.sortDesc[index],
        },
      );
    }

    return sortBy;
  }

  public generateParams(isPaginating: boolean = true, isCounting: boolean = false) {
    const data: any = {
      measure: (this.measure) ? this.measure.code : null,
      dimension: (this.dimension) ? this.dimension.code : null,
      analyticsType: (this.analyticsType) ? this.analyticsType.code : null,
      isDetail: `${this.isDetail}`,
      is_metric: `${this.isMetric}`,
      comparison_period: (this.comparisonDate) ? this.comparisonDate.code : null,
    };
    if (isPaginating) {
      data.per_page = this.perPage;
      data.page = (isCounting) ? -1 : this.page;
    }
    
    data.sort_by = JSON.stringify(this.generateSortByParam());
    if (this.filterTimerange && this.filterTimerange.length > 0) {
      if (this.filterTimerange[0]) {
        data.from = this.filterTimerange[0];
      }
      if (this.filterTimerange[1]) {
        data.to = this.filterTimerange[1];
      }
    }
    if (this.filterDimensions) {
      for (const item of this.filterDimensions.items) {
        if (item.dimension && item.dimension.code in data) {
          if (!data[item.dimension.code].includes(`${item.id}`)) {
            data[item.dimension.code] += `,${item.id}`;
          }
        } else {
          data[item.dimension.code] = item.id;
        }
      }
    }
    if (this.filterColumns && this.filterColumns.items.length > 0) {
      const filterParams = [];
      for (const item of this.filterColumns.items) {
        filterParams.push(`${item.code}__${item.operator}__${item.value.join('_or_')}__${item.dataType}`);
      }
      data.column_filter = filterParams.join('_col_');
    }

    if (this.reportParams.size()) {
      const params = [];
      for (const item of this.reportParams.items) {
        params.push(`${item.name}__${item.code}__${item.dataType}__${item.valueType}__${item.value.join('_or_')}`);
      }
      if (params.length > 0) {
        data.params = params.join('_param_');
      }
    }
    if (this.pivotOption && this.pivotOption !== 'undefined') {
      data.pivot_option = this.pivotOption;
    }
    if (this.reportColumns && this.reportColumns.length > 0) {
      
      const columnParams: any = [];
      for (const item of this.reportColumns) {
        columnParams.push(item.code);
      }
      data.reportColumns = columnParams.join('__');
    }
    if (this.calculatedMetrics && this.calculatedMetrics.items.length > 0) {
      data.calculated_metrics = JSON.stringify(this.calculatedMetrics.items);
    }
    if (this.productSegment && this.productSegment.id) {
      data.product_segment = this.productSegment.id;
    }
    return data;
  }

  private generateNextPageParams() {
    const data: any = {
      measure: (this.measure) ? this.measure.code : null,
      dimension: (this.dimension) ? this.dimension.code : null,
      analyticsType: (this.analyticsType) ? this.analyticsType.code : null,
      isDetail: this.isDetail,
    };
    data.page = Number(this.page) + 1;
    data.per_page = this.perPage;
    data.sort_by = this.sortBy;
    data.sort_desc = this.sortDesc;

    if (this.filterTimerange && this.filterTimerange.length > 0) {
      if (this.filterTimerange[0]) {
        data.from = this.filterTimerange[0];
      }
      if (this.filterTimerange[1]) {
        data.to = this.filterTimerange[1];
      }
    }
    if (this.filterDimensions) {
      for (const item of this.filterDimensions.items) {
        if (item.dimension && item.dimension.code in data) {
          if (!data[item.dimension.code].includes(`${item.id}`)) {
            data[item.dimension.code] += `,${item.id}`;
          }
        } else {
          data[item.dimension.code] = item.id;
        }
      }
    }
    if (this.productSegment && this.productSegment.id) {
      data.product_segment = this.productSegment.id;
    }
    return data;
  }

  private toTimestamp(strDate: string = '') {
    let datum: number = 0;
    if (!strDate) {
      datum = Date.now();
    } else {
      datum = Date.parse(strDate);
    }
    return datum / 1000;
  }

  private clearCache() {
    for (const key of Object.keys(localStorage)) {
      if (key.includes(`cache_${md5(this.token)}_${md5(this.reportPath)}`)) {
        localStorage.removeItem(key);
      }
    }
  }
}
