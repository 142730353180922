
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { EventBus } from '@/main';
import { Container, Draggable } from 'vue-smooth-dnd';
import ActionButton from '@/components/ActionButton.vue';
import { FilterColumnList } from '@/collections/filter_columns';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { ReportModel } from '@/models/report';
import { StoreModel } from '@/models/store';
import { MetricModel } from '@/models/metric';
import { MetricList } from '@/collections/metrics';
import { DimensionColumnList } from '@/collections/dimension_columns';

// Import child components
import ColumnSelectorDialog from './column-selector/ColumnSelectorDialog.vue';
import DraggableColumnList from './column-selector/DraggableColumnList.vue';
import PivotOptions from './column-selector/PivotOptions.vue';
import CalculatedMetrics from './column-selector/CalculatedMetrics.vue';

interface ColumnGroup {
  text: string;
  value: string;
  items: any[];
}

@Component({
  components: {
    Container,
    Draggable,
    ActionButton,
    ColumnSelectorDialog,
    DraggableColumnList,
    PivotOptions,
    CalculatedMetrics,
  },
})
export default class ColumnSelector extends Vue {
  @Prop({ default: null })
  public value!: any[];
  public columnSelector: boolean = false;
  @Prop({ default: false })
  public columnSelectorMenu!: boolean;
  public isLoadingColumn: boolean = false;
  public report: ReportModel = new ReportModel();
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => new MeasureModel() })
  public measure!: MeasureModel;
  @Prop({ default: () => new DimensionModel() })
  public dimension!: DimensionModel;
  public selectedDimension: DimensionModel = new DimensionModel();
  @Prop({ default: () => new AnalyticsTypeModel() })
  public analyticsType!: AnalyticsTypeModel;
  @Prop({ default: '' })
  public pivotOption!: string;
  @Prop({ default: [] })
  public filterTimerange!: string[];
  @Prop({ default: [] })
  public initReportColumns!: any;
  public reportColumns: any = [];
  @Prop({ default: [] })
  public initSelectedReportColumns!: any;
  public selectedReportColumns: any = [];
  public selectingItems: any = [];
  @Prop({ default: () => new DimensionColumnList() })
  public dimensionColumns!: DimensionColumnList;
  public columnGroups: any = [];
  public defaultColumns: any = [];
  public dialogAddDimensionColumn: boolean = false;
  public dialogPivotOption: boolean = false;
  public selectedPivotColumns: any = [];
  @Prop({ default: 'column' })
  public currentTab!: string;
  public dialogEditColumnConfig: boolean = false;
  public editingColumnIndex: number = -1;
  public newColumnName: string = '';
  @Prop({ default: 0 })
  public customReportId!: number;
  @Prop({ default: 0 })
  public templateReportId!: number;
  public newCalculatedMetric: MetricModel = new MetricModel();
  @Prop({ default: () => new MetricList() })
  public initCalculatedMetrics!: MetricList;
  public calculatedMetrics: MetricList = new MetricList();
  public dialogDelete: boolean = false;
  public deletingMetric: MetricModel = new MetricModel();
  public activeMetricIndex: number = -1;
  public nameRules: any = [this.isNameExist];
  public metricDataTypes: any = [
    { label: 'Number', value: 'number' },
    { label: 'Percentage', value: 'percentage' },
    { label: 'Text', value: 'text' },
  ];
  public selectAll: boolean = false;
  public columnSearch: string = '';
  public selectedColumns: { [key: string]: boolean } = {};
  public filteredColumnGroups: any[] = [];
  private readonly TEMP_CHANGES_KEY = 'column_selector_temp_changes';
  private readonly UI = Vue.observable({
    dialogs: {
      addColumn: false,
      editConfig: false,
      delete: false
    },
    loading: false,
    error: null as Error | null
  });

  public created() {
    this.initSelectingItems();
    this.initDimensionColumn();
    EventBus.$on('open-add-more-column-dialog-event', async () => {
      this.UI.dialogs.addColumn = true;
    });
    this.filteredColumnGroups = this.columnGroups;
    this.initializeSelectedColumns();
  }

  public initializeSelectedColumns(): void {
    this.selectedColumns = {};
    const savedChanges = localStorage.getItem(this.TEMP_CHANGES_KEY);
    const tempChanges = savedChanges ? JSON.parse(savedChanges) : {};
    
    this.columnGroups.forEach((group: { items: any[] }) => {
      group.items.forEach((dimension: any) => {
        // Check if column exists in selectingItems and is selected (value: true)
        const isCurrentlySelected = this.selectingItems.some(
          (item: { column: { code: string }, value: boolean }) => 
            item.column.code === dimension.code && item.value
        );
        
        this.selectedColumns[dimension.code] = 
          dimension.code in tempChanges 
            ? tempChanges[dimension.code] 
            : isCurrentlySelected;
      });
    });
  }

  @Watch('selectedColumns', { deep: true })
  private onSelectedColumnsChange(): void {
    const tempChanges: { [key: string]: boolean } = {};
    Object.entries(this.selectedColumns).forEach(([code, isSelected]) => {
      const currentlyAdded = this.isAddedColumn(code);
      if (isSelected !== currentlyAdded) {
        tempChanges[code] = isSelected;
      }
    });
    localStorage.setItem(this.TEMP_CHANGES_KEY, JSON.stringify(tempChanges));
  }

  public get hasColumnChanges(): boolean {
    const savedChanges = localStorage.getItem(this.TEMP_CHANGES_KEY);
    return savedChanges ? Object.keys(JSON.parse(savedChanges)).length > 0 : false;
  }

  public applyColumnChanges(): void {
    // Collect all columns to add and remove
    const columnsToAdd: any[] = [];
    const codesToRemove = new Set<string>();

    Object.entries(this.selectedColumns).forEach(([code, isSelected]) => {
      const currentlyAdded = this.isAddedColumn(code);
      if (isSelected && !currentlyAdded) {
        const dimension = this.findDimensionByCode(code);
        if (dimension) {
          columnsToAdd.push({
            column: dimension,
            value: true,
            newName: dimension.name,
            sort: '',
          });
        }
      } else if (!isSelected && currentlyAdded) {
        codesToRemove.add(code);
      }
    });

    // Batch add new columns
    if (columnsToAdd.length > 0) {
      this.selectingItems.push(...columnsToAdd);
    }

    // Batch remove columns
    if (codesToRemove.size > 0) {
      this.selectingItems = this.selectingItems.filter(
        (item: any) => !codesToRemove.has(item.column.code)
      );
    }

    this.saveColumn();
    localStorage.removeItem(this.TEMP_CHANGES_KEY);
    this.UI.dialogs.addColumn = false;
  }

  public findDimensionByCode(code: string): any {
    for (const group of this.columnGroups) {
      const dimension = group.items.find((d: any) => d.code === code);
      if (dimension) return dimension;
    }
    return null;
  }

  @Watch('UI.dialogs.addColumn')
  private onDialogOpen(isOpen: boolean): void {
    if (isOpen) {
      this.columnSearch = '';
      this.initializeSelectedColumns();
      this.filterColumnsBySearch();
    }
  }

  public isNameExist(v: any) {
    return !(!v && this.newCalculatedMetric.name) || 'Must not be empty';
  }

  public confirmDeleteMetric(metric: MetricModel, index: number) {
    this.deletingMetric = metric;
    this.activeMetricIndex = index;
    this.dialogDelete = true;
  }

  public editMetric(metric: MetricModel, index: number) {
    this.newCalculatedMetric = metric;
    this.activeMetricIndex = index;
  }

  public cancelEditMetric() {
    this.activeMetricIndex = -1;
    this.newCalculatedMetric = new MetricModel();
  }

  public deleteMetric() {
    if (
      this.activeMetricIndex > -1 &&
      this.activeMetricIndex < this.calculatedMetrics.items.length
    ) {
      this.calculatedMetrics.items.splice(this.activeMetricIndex, 1);
      this.activeMetricIndex = -1;
      this.deletingMetric = new MetricModel();
      this.$emit('update-calculated-metric', {
        metrics: this.calculatedMetrics,
      });
      this.autoAddCalculatedMetricToReport(this.calculatedMetrics);
      this.dialogDelete = false;
    }
  }

  public autoAddCalculatedMetricToReport(metrics: any) {
    // Handle single metric case
    for (const metric of metrics) {
      const exists = this.initSelectedReportColumns.some((item: any) => item.code === metric.code);
      if (!exists) {
        const column = {
          name: metric.name,
          code: metric.code,
          dataType: metric.dataType,
          luisMapping: metric.code,
        };
        this.initSelectedReportColumns.push(column);
      }
    }
    this.initSelectingItems();
    this.saveColumnAndClose();
  }

  public get actionTitle() {
    switch (this.currentTab) {
      case 'pivot': {
        return 'Column Pivot Table';
      }

      case 'metric': {
        return 'Create Calculated Metrics';
      }

      default: {
        return 'Manage Columns for Report';
      }
    }
  }

  public getShortColumnName(columnName: string) {
    const max: number = 70;
    const len = columnName.length;
    let substr = columnName.substring(0, max);
    if (len >= max) {
      substr = substr + ' ...';
    }
    return substr;
  }

  public async initDimensionColumn() {
    this.columnGroups = [
      { text: 'Order Details', value: 'order_detail', items: [] },
      { text: 'Refunds', value: 'refunds', items: [] },
      { text: 'Product', value: 'product', items: [] },
      { text: 'Fulfillment', value: 'fulfillment', items: [] },
      { text: 'Tax', value: 'tax', items: [] },
      { text: 'Discounts', value: 'discounts', items: [] },
      { text: 'Shipping', value: 'shipping', items: [] },
      { text: 'Transaction', value: 'transaction', items: [] },
      { text: 'Customer Joureny', value: 'customer_journey', items: [] },
      { text: 'Billing / Payment', value: 'billing_payment', items: [] },
      { text: 'Customer', value: 'customer', items: [] },
      { text: 'Client Details', value: 'client_details', items: [] },
      { text: 'Presentments', value: 'presentments', items: [] },
      { text: 'Staffs', value: 'staff', items: [] },
      { text: 'Store Location', value: 'store_location', items: [] },
      { text: 'Sales Channel', value: 'sales_channel', items: [] },
      { text: 'Vendor', value: 'vendor', items: [] },
      { text: 'datetime', value: 'datetime', items: [] },
    ];
    await this.refreshColumnGroup();
  }

  public enableRename(index: number) {
    this.editingColumnIndex = index;
    this.dialogEditColumnConfig = true;
    this.newColumnName = this.selectingItems[index].newName;
  }

  public updateColumnConfig() {
    this.dialogEditColumnConfig = true;
    if (this.editingColumnIndex === -1) {
      return;
    }
    const index = this.editingColumnIndex;
    this.selectingItems[index].newName = this.newColumnName;
    this.newColumnName = '';
    this.editingColumnIndex = -1;
    this.dialogEditColumnConfig = false;
  }

  public massSelectItem() {
    for (const item of this.selectingItems) {
      item.value = this.selectAll;
    }
  }

  public initSelectingItems() {
    this.selectedReportColumns = [];
    for (const item of this.initSelectedReportColumns) {
      this.selectedReportColumns.push(item);
    }

    this.selectingItems = [];
    for (const item of this.selectedReportColumns) {
      this.selectingItems.push({
        column: item,
        value: true,
        newName: item.name,
        sort: '',
        autoSelect: item.autoSelect,
      });
    }
    for (const item of this.reportColumns) {
      if (!this.isSelectedColumn(item.code)) {
        this.selectingItems.push({
          column: item,
          value: false,
          newName: item.name,
          sort: '',
          autoSelect: item.autoSelect,
        });
      }
    }
  }

  public get canAddPivotOption() {
    if (!this.selectedPivotColumns || this.selectedPivotColumns.length === 0) {
      return false;
    }
    return true;
  }

  public async refreshColumnGroup() {
    if (!this.dimensionColumns || this.dimensionColumns.size() === 0) {
      return;
    }
    const newColumnGroups: any = [];
    for (const group of this.columnGroups) {
      const items = await this.getDimensionColumns(group.value);
      if (items && items.length > 0) {
        group.items = items;
        newColumnGroups.push(group);
      }
    }
    this.columnGroups = newColumnGroups;
    for (const index in this.columnGroups) {
      const group = this.columnGroups[index];
      this.defaultColumns.push({ header: group.text });
      for (const column of group.items) {
        this.defaultColumns.push(column);
      }
      if (parseInt(index) === this.columnGroups.length - 1) {
        this.defaultColumns.push({ divider: true });
      }
    }
  }

  public async getDimensionColumns(group: string) {
    const columns: any = [];
    for (const item of this.dimensionColumns.items) {
      if (
        item.group_name === group &&
        (await this.measure.isSupportedColumn(item.code))
      ) {
        if (!this.isAddedColumn(item.code)) {
          columns.push(item);
        }
      }
    }
    return columns;
  }

  public addColumn(dimensionColumn: any) {
    this.selectingItems.push({
      column: dimensionColumn,
      value: true,
      newName: dimensionColumn.name,
      sort: '',
    });
    this.dialogAddDimensionColumn = false;
    this.saveColumn();
    this.refreshColumnGroup();
    this.columnSelector = true;
    this.$emit('need-refresh-table-data');
  }
  public onDrop(result: any) {
    this.selectingItems = this.applyDrag(this.selectingItems, result);
  }

  public applyDrag(result: any, dragResult: any) {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) {
      return result;
    }
    let itemToAdd = payload;
    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    return result;
  }

  public isSelectedColumn(code: string) {
    if (this.selectingItems && this.selectingItems.length > 0) {
      for (const item of this.selectingItems) {
        if (item.column.code === code && item.value) {
          return true;
        }
      }
    }
    return false;
  }

  public isAddedColumn(code: string) {
    if (!this.selectingItems || this.selectingItems.length === 0) {
      return false;
    }
    for (const item of this.selectingItems) {
      if (item.column.code === code && item.value) {
        return true;
      }
    }
    return false;
  }

  public async showColumnSelector() {
    this.columnSelector = true;
    this.initSelectingItems();
  }

  public getSelectedPivotColumns() {
    this.selectedPivotColumns = [];
    const selectedPivotColumns: any = [];
    if (!this.pivotOption || this.pivotOption === 'undefined') {
      return [];
    } else {
      let hasSelected: boolean = false;
      for (const item of this.reportColumns) {
        if (
          item.code.indexOf('_pivot_') !== -1 &&
          this.isSelectedColumn(item.code)
        ) {
          hasSelected = true;
          break;
        }
      }
      if (!hasSelected) {
        for (const item of this.reportColumns) {
          if (item.code.indexOf('_pivot_') !== -1) {
            selectedPivotColumns.push(item);
          }
        }
      }

      return selectedPivotColumns;
    }
  }

  public saveColumn() {
    const selectedItems: any = [];
    for (const item of this.selectingItems) {
      if (item.value) {
        const column = {
          name: item.newName,
          code: item.column.code,
          dataType: item.column.dataType,
          luisMapping: item.column.luisMapping,
          autoSelect: item.autoSelect,
        };
        selectedItems.push(column);
      }
    }

    this.$emit('input', selectedItems);
    this.$emit('need-refresh-table-data');
  }

  public saveColumnAndClose() {
    const selectedItems: any = [];
    for (const item of this.selectingItems) {
      if (item.value) {
        const column = {
          name: item.newName,
          code: item.column.code,
          dataType: item.column.dataType,
          luisMapping: item.column.luisMapping,
          autoSelect: item.autoSelect,
        };
        selectedItems.push(column);
      }
    }
    this.$emit('input', selectedItems);
    this.$emit('need-refresh-table-data');
    EventBus.$emit('reset-active-column-view');
    EventBus.$emit('close-column-selector-form');
  }

  @Watch('columnSelectorMenu', { immediate: true, deep: true })
  private async onColumnSelectorMenuChange(newVal: any) {
    if (newVal && newVal === true) {
      this.columnSelector = this.columnSelectorMenu;
    }
  }

  @Watch('initReportColumns', { immediate: true, deep: true })
  private async onReportColumnsChange(newVal: any) {
    if (newVal && this.initReportColumns && this.initReportColumns.length > 0) {
      this.reportColumns = [...this.initReportColumns];
      this.initSelectingItems();
    }
  }

  @Watch('dimensionColumns', { immediate: true, deep: true })
  private async onDimensionColumnsChange(newVal: any) {
    if (this.dimensionColumns && this.dimensionColumns.size() > 0) {
      this.refreshColumnGroup();
    }
  }

  @Watch('initCalculatedMetrics', { immediate: true, deep: true })
  private async initCalculatedMetricsChanged(newVal: any) {
    if (
      this.initCalculatedMetrics &&
      this.initCalculatedMetrics.items.length > 0
    ) {
      this.calculatedMetrics = this.initCalculatedMetrics;
    }
  }

  @Watch('dialogDelete', { immediate: true, deep: true })
  private async dialogDeleteChanged(newVal: any) {
    if (!this.dialogDelete) {
      this.activeMetricIndex = -1;
      this.deletingMetric = new MetricModel();
    }
  }

  public filterColumnsBySearch(): void {
    if (!this.columnSearch) {
      this.filteredColumnGroups = this.columnGroups;
      return;
    }

    const searchTerm = this.columnSearch.toLowerCase();
    this.filteredColumnGroups = this.columnGroups
      .map((group: ColumnGroup) => ({
        ...group,
        items: group.items.filter((dimension: any) => 
          dimension.name.toLowerCase().includes(searchTerm)
        ),
      }))
      .filter((group: ColumnGroup) => group.items.length > 0);
  }
  // I want to watch the initSelectedReportColumns and call the initSelectingItems
  @Watch('initSelectedReportColumns', { immediate: true, deep: true })
  private async onInitSelectedReportColumnsChange(newVal: any) {
    // check if the newVal is an array and the selectingItems is not empty
    if (newVal && Array.isArray(newVal) && this.selectingItems.length > 0) {
      this.initSelectingItems();
    }
  }

  private handlePivotChange(option: string): void {
    EventBus.$emit('update-pivot-option', option);
    this.initSelectingItems();
    this.$emit('need-refresh-table-data');
  }

  private handleMetricChange(metrics: any[]): void {
    this.calculatedMetrics.items = metrics;
    this.$emit('update-calculated-metric', {metrics: this.calculatedMetrics});
    this.autoAddCalculatedMetricToReport(metrics);
  }
}
