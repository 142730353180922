import {get, put} from '@/services/http';
import { DiscountModel } from '@/models/discount';
import List from './list';

export class DiscountList extends List<DiscountModel> {
  public async fetch() {
    const res: any = await get('/discounts', {});
    const discounts = res.data || [];
    this.items = [];
    for (const s of discounts) {
      const discount = new DiscountModel();
      discount.mapData(s);
      this.add(discount);
    }
  }
}

