interface Column {
  name: string;
  code: string;
  values: any[];
  originalValues: string[];
  dataType: string;
  filterable: boolean;
  sortable: boolean;
  luisMapping: string;
  comparison: any;
  description: string;
  colorIndex: boolean;
  autoSelect: boolean;
}

export class PivotDataModel {
  public row?: Column;
  public column?: Column;
  public value?: Column;
  public summarizeType?: string;
}
