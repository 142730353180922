
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import Chart from 'chart.js/auto';
import { ReportModel } from '@/models/report';
import { EventHandler } from '@/modules/events';
import { StoreModel } from '@/models/store';

interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
}

@Component({})
export default class ReportCardTable extends Vue {
  public headers: any = [];
  public rows: any = [];
  public chart?: any;
  @Prop({default: false})
  public needReload!: boolean;
  @Prop({default: []})
  public selectedReportColumns!: any;
  @Prop({default: new ReportModel()})
  public report!: ReportModel;
  @Prop({default: true})
  public autoRemoveTextColumn!: boolean;
  @Prop({default: () => {}})
  public initOptions!: Option;
  public options: Option = {};
  public fisrtTimeInitOption: boolean = false;
  public headerMenuY: number = 0;
  public headerMenuX: number = 0;
  public showHeaderOptionMenu: boolean = false;
  public selectingHeader: any = {};
  public page!: any;
  public perPage!: any;
  public sortBy!: any;
  public sortDesc!: any;
  @Prop({default: 1})
  public height!: number;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;

  public created() {
    return;
  }

  public sendMixpanelEvent(eventName: string = '', data: any = {}) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(eventName, data);
    } catch (e) {
      // ignore this
    }
  }

  public get getHeight() {
    return (this.height + 1) * 100;
  }
  public getItemMenuPosition() {
    const e = window.event;
    if (e === undefined) {
      return;
    }
    if (e && e !== undefined) {
      // @ts-ignore: Unreachable code error
      this.headerMenuX = e.clientX;
      // @ts-ignore: Unreachable code error
      this.headerMenuY = e.clientY + 20;
    }
  }

  public hideContextMenu() {
    this.showHeaderOptionMenu = false;
  }

  public headerOptionMenuClick(header: any) {

    this.showHeaderOptionMenu = true;
    this.selectingHeader = header;
    this.getItemMenuPosition();
  }

  public isActiveSort(isDesc: boolean = true) {
    if (!this.sortDesc || this.sortDesc.length === 0 || !this.sortBy || this.sortBy.length === 0) {
      return false;
    }
    let isSorted: boolean = false;
    let sortDesc: boolean = false;
    let index: number = 0;
    for (const sortBy of this.sortBy) {
      if (sortBy === this.selectingHeader.value) {
        sortDesc = this.sortDesc[index];
        isSorted = true;
        break;
      }
      index++;
    }
    if (isSorted && sortDesc === isDesc) {
      return true;
    }
    return false;
  }

  public getSortIndex(headerCode: string) {
    if (!this.sortDesc || this.sortDesc.length < 2 || !this.sortBy || this.sortBy.length < 2) {
      return 0;
    }
    let index: number = 0;
    for (const sortBy of this.sortBy) {
      if (sortBy === headerCode) {
        return index + 1;
      }
      index++;
    }
    return 0;
  }

  public showSortArrow(headerCode: string, isDesc: boolean = true) {
    if (!this.sortDesc || this.sortDesc.length === 0 || !this.sortBy || this.sortBy.length === 0) {
      return false;
    }

    let isSorted: boolean = false;
    let sortDesc: boolean = false;
    let index: number = 0;
    for (const sortBy of this.sortBy) {
      if (sortBy === headerCode) {
        sortDesc = this.sortDesc[index];
        isSorted = true;
        break;
      }
      index++;
    }
    if (isSorted && sortDesc === isDesc) {
      return true;
    }
    return false;
  }

  public applySortColumn(sortDesc: boolean = true) {
    // check if the selecting column is sorted or not
    let isSorted: boolean = false;
    let index: number = 0;
    let isSortDesc: boolean = false;
    if (!this.options.sortBy || this.options.sortBy.length === 0) {
      this.options.sortBy = [];
      this.options.sortDesc = [];
    }
    for (const item of this.options.sortBy) {
      if (item === this.selectingHeader.value) {
        isSorted = true;
        isSortDesc = this.options.sortDesc[index];
        break;
      }
      index++;
    }
    if (isSorted && isSortDesc === sortDesc) {
      // remove sort for this columns
      this.options.sortBy.splice(index, 1);
      this.options.sortDesc.splice(index, 1);
    } else if (isSorted && isSortDesc !== sortDesc) {
      // change direction of the sort for this column
      this.options.sortDesc[index] = sortDesc;
    } else {
      // add new sort for this columns
      this.options.sortBy[0] = this.selectingHeader.value;
      this.options.sortDesc[0] = sortDesc;
    }
    this.optionChanged();
    this.showHeaderOptionMenu = false;
    this.sendMixpanelEvent('Sort Card Column', {columnName: this.selectingHeader.text, columnCode: this.selectingHeader.value});
  }

  public reloadTable() {
    this.removeUnselectedColumn();
    this.headers = [];
    this.rows = [];
    let selectedReportColumns: any = this.selectedReportColumns;
    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      selectedReportColumns = this.report.columns;
    }


    for (const c of selectedReportColumns) {
      const col = this.report.columns.find((x) => x.code === c.code);
      this.headers.push(
      {
        text: c.name,
        value: c.code,
        class: (c.sortable !== false) ? 'table-header' : ['table-header', 'non-sortable-header'],
        sortable: (c.sortable !== false) ? true : false,
        filterable: (c.filterable !== false) ? true : false,
        cellClass: (c.sortable !== false) ? 'table-item' : ['table-item', 'non-sortable-item'],
        colorIndex: col ? col.colorIndex : false,
      } );
    }


    for (const index in this.report.rows) {
      if (this.report.rows[index]) {
        const res: any = {};
        for (const column of this.report.columns) {
          if (!this.isSelectedColumn(column.code)) {
            continue;
          }
          if (column.values[index]) {
            res[column.code] = column.values[index];
          } else {
            if (column.dataType === 'number'
              || column.dataType === 'decimal'
              || column.dataType === 'currency'
              || column.dataType === 'percent') {
              res[column.code] = '0';
            } else {
              res[column.code] = '-';
            }
          }
        }
        this.rows.push(res);
      }
    }
    this.limitColumnShown();
    this.$emit('reload-completed', 'TABLE');
  }

  public limitColumnShown() {
    if (!this.autoRemoveTextColumn) {
      return;
    }
    const maxColumns = 4;
    const headers = [];
    const columns = [];
    let i = 0;
    for (const item of this.headers) {
      headers.push(item);
      i++;
      if (i >= maxColumns) {
        break;
      }
    }
    this.headers = headers;
  }
  public isSelectedColumn(code: string) {
    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      return true;
    }
    for (const c of this.selectedReportColumns) {
      if (c.code === code) {
        return true;
      }
    }
    return false;
  }

  public removeUnselectedColumn() {
    const report = new ReportModel();
    let isFirst = true;
    for (const column of this.report.columns) {
      if (this.isSelectedColumn(column.code)) {
        if (['text', 'date', 'datetime'].includes(column.dataType) && !isFirst && this.autoRemoveTextColumn) {
          continue;
        }
        isFirst = false;
        report.columns.push(column);
      }
    }
    this.report.columns = report.columns;
  }

  public isOptionsChanged() {
    if (this.page !== this.options.page) {
      return true;
    }
    if (this.perPage !== this.options.itemsPerPage) {
      return true;
    }
    if (this.options.sortBy
      && this.options.sortBy.length
      && this.sortBy
      && this.sortBy.length > 0
      && this.options.sortBy.length !== this.sortBy.length
      ) {
      return true;
    }
    if (this.options.sortDesc
      && this.options.sortDesc.length > 0
      && this.sortDesc && this.sortDesc.length > 0
      && this.sortDesc.length !== this.options.sortDesc.length
      ) {
      return true;
    }

    if (this.options.sortDesc && this.sortDesc && this.options.sortDesc.length === this.sortDesc.length) {
      for (let index = 0;  index < this.options.sortDesc.length; index++ ) {
        if (this.options.sortDesc[index] !== this.sortDesc[index]) {
          return true;
        }
      }
    }

    if (this.options.sortBy && this.sortBy && this.options.sortBy.length === this.sortBy.length) {
      for (let index = 0;  index < this.options.sortDesc.length; index++ ) {
        if (this.options.sortBy[index] !== this.sortBy[index]) {
          return true;
        }
      }
    }
    return false;
  }

  public async optionChanged() {
    this.page = this.options.page;
    this.perPage = this.options.itemsPerPage;
    this.sortBy = this.options.sortBy;
    this.sortDesc = this.options.sortDesc;
    this.$emit('update-card-table-option', this.options);
  }

  @Watch('needReload', { immediate: true, deep: true  })
  private async onNeedReloadChanged(newVal: any, oldVal: any) {
    if (newVal === true) {
      this.reloadTable();
    }
  }

  @Watch('initOptions', { immediate: true, deep: true })
  private async initOptionsChanged(newVal: any, oldVal: any) {
    if (this.initOptions && this.initOptions.sortBy) {
      this.options = this.initOptions;
      this.page = this.options.page;
      this.perPage = this.options.itemsPerPage;
      this.sortBy = this.options.sortBy;
      this.sortDesc = this.options.sortDesc;
      this.fisrtTimeInitOption = true;
    }
  }

  @Watch('options', { immediate: true, deep: true })
  private async optionsChanged(newVal: any, oldVal: any) {
    if (this.fisrtTimeInitOption) {
      this.fisrtTimeInitOption = false;
      return;
    }
    if (this.isOptionsChanged()) {
      this.optionChanged();
    }
  }
}
