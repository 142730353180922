
import { Component, Prop, Vue } from "vue-property-decorator";
import { EventHandler } from "@/modules/events";
import HeaderTitle from "../components/HeaderTitle.vue";
import ChatWithUs from "../components/ChatWithUs.vue";
import { ScheduleModel } from "@/models/schedule";
import { ScheduleList } from "@/collections/schedules";
import { MeasureList } from "@/collections/measures";
import { StoreModel } from "@/models/store";
import FeatureLockedNotify from "@/components/FeatureLockedNotify.vue";
import ActionButton from "@/components/ActionButton.vue";
import EmptyStage from "@/components/EmptyStage.vue";
import VideoGuides from "@/components/VideoGuides.vue";
import { EventBus } from "@/main";
import { EmailReceiverList } from "@/collections/email_receivers";
import InteractiveGuides from '@/components/InteractiveGuides.vue';

@Component({
  components: {
    HeaderTitle,
    ChatWithUs,
    FeatureLockedNotify,
    ActionButton,
    EmptyStage,
    VideoGuides,
    InteractiveGuides,
  },
})
export default class Schedules extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public isLoading: boolean = false;
  public schedules: ScheduleList = new ScheduleList();
  public deletingSchedule: ScheduleModel = new ScheduleModel();
  public dialogDelete: boolean = false;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public scheduleHeaders: any = [];
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  public deleteLoading: boolean = false;
  public videoGuideLinks: any = [
    {
      title: 'How to Schedule a Report',
      link: 'https://www.youtube.com/embed/Vm56P6FECUI?si=BHsn5Erd5BGvWGDP',
    },
  ];
  public emailReceivers: EmailReceiverList = new EmailReceiverList();
  public search: string = '';

  public async created() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`view Schedule listing page`);
    } catch (e) {
      // ignore this
    }
    this.isLoading = true;

    this.scheduleHeaders = [
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Destination",
        value: "destination",
      },
      {
        text: "Output Type",
        value: "outputType",
      },
      {
        text: "File Format",
        value: "fileFormat",
      },
      {
        text: "Interval",
        value: "interval",
      },
      {
        text: "Time",
        value: "time",
      },
      {
        text: "Created at",
        value: "createdAt",
      },
      {
        text: "Upcoming at",
        value: "upcomingAt",
      },
      {
        text: this.$t("schedule.action") + "",
        value: "action",
        width: "10%",
      },
    ];
    await this.fullLoadData();
    this.isLoading = false;
  }

  public canScheduleReport() {
    return true;
  }

  public getFileFormatText(fileFormat: string) {
    switch (fileFormat) {
      case "csv":
        return "CSV";
      case "excel":
        return "Excel";
      case "pdf":
        return "PDF";
      case "existed_google_sheets":
        return "Create a single sheet";
      case "existed_google_new_sheets":
        return "Add a new sheet on every run";
      case "google_sheets":
        return "New Google Spreadsheet";
      default:
        return fileFormat;
    }
  }
  public getDeliveryHourLabel(profile: ScheduleModel) {
    let timeLabel: string = '';
    if (profile.deliveryHour && profile.deliveryHour.length > 0) {
      let hours: string[] = [];
      for (const item of profile.deliveryHour) {
        hours.push(item + ':00');
      }
      timeLabel = hours.join(', ');
    }
    return timeLabel;
  }

  public getDeliveryWeekdayLabel(profile: ScheduleModel) {
    let timeLabel: string = '';
    if (profile.deliveryWeekday && profile.deliveryWeekday.length > 0) {
      let weekdayLabels: string[] = [];
      for (const item of profile.deliveryWeekday) {
        weekdayLabels.push(this.convertWeekdayToLabel(item));
      }
      timeLabel = weekdayLabels.join(', ');
    }
    return timeLabel;
  }

  public getDeliveryDateLabel(profile: ScheduleModel) {
    let timeLabel: string = '';
    if (profile.deliveryDate && profile.deliveryDate.length > 0) {
      let dates: string[] = [];
      for (const item of profile.deliveryDate) {
        dates.push(profile.convertDayOfMonthToLabel(item));
      }
      timeLabel = dates.join(', ');
    }
    return timeLabel;
  }

  public convertWeekdayToLabel(weekday: string) {
    let weekdayLabels: any = [
      {value: '0', label: 'Sunday'},
      {value: '1', label: 'Monday'},
      {value: '2', label: 'Tuesday'},
      {value: '3', label: 'Wednesday'},
      {value: '4', label: 'Thursday'},
      {value: '5', label: 'Friday'},
      {value: '6', label: 'Saturday'},
      {value: 'everyday', label: 'Everyday'}
    ];
    for (const item of weekdayLabels) {
      if (item.value === weekday + '') {
        return item.label;
      }
    }
    return '';
  }
  public getScheduleReportName(data: any) {
    if (data.name) {
      return data.name;
    }
    let reportName = "";
    for (const measure of this.measures.items) {
      if (measure.code === data.measureCode) {
        reportName = measure.name;
        for (const dimension of measure.dimensions.items) {
          if (dimension.code === data.dimensionCode) {
            reportName += " by " + dimension.name;
            break;
          }
        }
        return reportName;
      }
    }
    return "";
  }

  public async fullLoadData() {
    this.isLoading = true;
    this.schedules = new ScheduleList();
    try {
      await this.emailReceivers.fetch();
      await this.schedules.fetch();
      for (const schedule of this.schedules.items) {
        await schedule.mapReceivers(this.emailReceivers);
      }
    } catch (e) {
      console.error(e);
      // ignore this
      EventBus.$emit("show-snackbar", {
        message: "Failed to load schedules",
        color: "error",
      });

    }
    this.isLoading = false;
  }

  public viewDetail(schedule: ScheduleModel) {
    this.$router.push(`/schedule/${schedule.id}`);
  }

  public confirmDelete(schedule: ScheduleModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`click delete schedule`);
    } catch (e) {
      // ignore this
    }
    this.deletingSchedule = schedule;
    this.dialogDelete = true;
  }

  public async deleteItem() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`delete schedule`, {});
    } catch (e) {
      // ignore this
    }
    this.deleteLoading = true;
    let index: number = -1;
    for (const item of this.schedules.items) {
      index = index + 1;
      if (item.id === this.deletingSchedule.id) {
        this.schedules.items.splice(index, 1);
        break;
      }
    }
    await this.deletingSchedule.remove();
    this.deleteLoading = false;
    this.dialogDelete = false;
  }

  public upperCaseFirstLetter(str: string) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }

  // Helper methods for UI
  public getStatusColor(status: string): string {
    const colors = {
      'ACTIVE': 'success',
      'INACTIVE': 'warning',
      'ERROR': 'error',
      'PENDING': 'info'
    };
    return colors[status as keyof typeof colors] || 'grey';
  }

  public getStatusIcon(status: string): string {
    const icons = {
      'ACTIVE': 'mdi-check-circle',
      'INACTIVE': 'mdi-pause-circle',
      'ERROR': 'mdi-alert-circle',
      'PENDING': 'mdi-clock'
    };
    return icons[status as keyof typeof icons] || 'mdi-help-circle';
  }

  public openLink(url: string): void {
    window.open(url, '_blank');
  }
}
