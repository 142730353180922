
import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';

@Component
export default class ColumnSelectorDialog extends Vue {
  @Model('change', { type: Boolean }) readonly value!: boolean;

  get dialogVisible(): boolean {
    return this.value;
  }

  set dialogVisible(value: boolean) {
    this.$emit('change', value);
  }
  @Prop({ type: Array, required: true }) columnGroups!: any[];
  @Prop({ type: Object, required: true }) selectedColumns!: { [key: string]: boolean };

  private search = '';
  private filteredGroups: any[] = [];

  @Watch('show')
  private onDialogOpen(isOpen: boolean): void {
    if (isOpen) {
      this.search = '';
      this.filterGroups();
    }
  }

  @Watch('columnGroups', { immediate: true })
  private onGroupsChange(): void {
    this.filteredGroups = this.columnGroups;
  }

  private onSearch(): void {
    this.filterGroups();
  }

  private filterGroups(): void {
    if (!this.search) {
      this.filteredGroups = this.columnGroups;
      return;
    }

    const searchTerm = this.search.toLowerCase();
    this.filteredGroups = this.columnGroups
      .map(group => ({
        ...group,
        items: group.items.filter((dimension: any) => 
          dimension.name.toLowerCase().includes(searchTerm)
        ),
      }))
      .filter(group => group.items.length > 0);
  }

  private onCancel(): void {
    this.$emit('cancel');
  }

  private onApply(): void {
    this.$emit('apply');
  }
}
