import { ref, computed } from 'vue'
import type { ColorRule } from '@/models/color_rule'

export const useColorRules = (initialRules: ColorRule[] = []) => {
  const rules = ref<ColorRule[]>(initialRules)
  const isEditing = ref(false)

  const addRule = (rule: Omit<ColorRule, 'id'>) => {
    rules.value.push({
      ...rule,
      id: crypto.randomUUID()
    })
  }

  const updateRule = (id: string, updates: Partial<ColorRule>) => {
    const index = rules.value.findIndex(rule => rule.id === id)
    if (index !== -1) {
      rules.value[index] = { ...rules.value[index], ...updates }
    }
  }

  const deleteRule = (id: string) => {
    rules.value = rules.value.filter(rule => rule.id !== id)
  }

  const validateRule = (value: any, rule: ColorRule): boolean => {
    switch (rule.operator) {
      case 'BETWEEN':
        return Array.isArray(rule.value) && 
          value >= rule.value[0] && value <= rule.value[1]
      case 'CONTAINS':
        return String(value).includes(String(rule.value))
      case 'REGEX':
        return new RegExp(String(rule.value)).test(String(value))
      case '>':
        return value > rule.value
      case '<':
        return value < rule.value
      case '>=':
        return value >= rule.value
      case '<=':
        return value <= rule.value
      case '=':
        return value === rule.value
      default:
        return false
    }
  }

  return {
    rules,
    isEditing,
    addRule,
    updateRule,
    deleteRule,
    validateRule
  }
} 