
import { defineComponent, PropType, ref, watch } from 'vue'
import { useColorRules } from '@/composables/useColorRules'
import type { ColorRule } from '@/models/color_rule'
import { Container, Draggable } from 'vue-smooth-dnd'

export default defineComponent({
  name: 'ColorRuleEditor',

  components: {
    Container,
    Draggable
  },

  props: {
    isCustomReport: {
      type: Boolean,
      required: true
    },
    initialRules: {
      type: Array as PropType<ColorRule[]>,
      default: () => []
    },
    columns: {
      type: Array as PropType<{ code: string; name: string }[]>,
      required: true
    },
    previewData: {
      type: Array as PropType<Record<string, any>[]>,
      required: true
    },
    measure: {
      type: Object as PropType<{ code: string }>,
      required: true
    }
  },

  emits: ['save', 'cancel', 'clone-report'],

  setup(props, { emit }) {
    const {
      rules,
      addRule,
      deleteRule,
      validateRule
    } = useColorRules(props.initialRules)
    const isCloning = ref(false)
    const isSaving = ref(false)
    const betweenValues = ref<{min: number, max: number}[]>([])

    // Watch for changes in initialRules prop
    watch(() => props.initialRules, (newRules) => {
      // Clear existing rules
      rules.value = [];
      // Add new rules
      newRules.forEach(rule => {
        addRule({
          ...rule,
          mapData: () => {}
        });
      });
      // Reinitialize betweenValues
      betweenValues.value = initializeBetweenValues(newRules);
    }, { deep: true });

    type ScopeItem = {
      text: string;
      value: 'row' | 'cell'|'report';
      applyToAll?: boolean;
    }

    const scopeItems: ScopeItem[] = [
      { text: 'Cell', value: 'cell' },
      { text: 'Entire Row', value: 'row' },
      { text: 'All Numeric Columns', value: 'report'}
    ]

    // Initialize betweenValues immediately with proper structure
    const initializeBetweenValues = (rules: ColorRule[]) => {
      return rules.map(rule => {
        if (rule.operator === 'BETWEEN' || rule.operator === 'PERCENTAGE_DIFFERENCE_BETWEEN') {
          const values = Array.isArray(rule.value) ? rule.value : [rule.value, rule.value];
          return { min: values[0], max: values[1] };
        }
        return { min: 0, max: 0 };
      });
    };

    // Initialize betweenValues immediately
    betweenValues.value = initializeBetweenValues(props.initialRules);

    // Watch for changes in rules
    watch(rules, (newRules) => {
      betweenValues.value = initializeBetweenValues(newRules);
    })

    const operators = [
      { text: 'Greater than', value: '>' },
      { text: 'Less than', value: '<' },
      { text: 'Equals', value: '=' },
      { text: 'Between', value: 'BETWEEN' },
      { text: 'Contains', value: 'CONTAINS' },
      { text: 'Greater than and % difference greater', value: 'GREATER_AND_DIFF_PERCENT_GREATER' },
      { text: 'Greater than and % difference less', value: 'GREATER_AND_DIFF_PERCENT_LESS' },
      { text: 'Percentage difference between', value: 'PERCENTAGE_DIFFERENCE_BETWEEN' },
      { text: 'Absolute percentage difference greater', value: 'PERCENTAGE_DIFFERENCE_ABS_GREATER' }
    ]

    const multiColumnOperators = [
      'GREATER_AND_DIFF_PERCENT_GREATER',
      'GREATER_AND_DIFF_PERCENT_LESS',
      'PERCENTAGE_DIFFERENCE_BETWEEN',
      'PERCENTAGE_DIFFERENCE_ABS_GREATER'
    ]

    const doubleValueOperators = [
      'BETWEEN',
      'PERCENTAGE_DIFFERENCE_BETWEEN'
    ]

    const isMultiColumnOperator = (operator: string) => {
      return multiColumnOperators.includes(operator)
    }

    const isDoubleValueOperator = (operator: string) => {
      return doubleValueOperators.includes(operator)
    }

    const handleOperatorChange = (rule: ColorRule) => {
      if (isMultiColumnOperator(rule.operator)) {
        if (!rule.secondColumnCode) {
          rule.secondColumnCode = props.columns[1]?.code || '';
        }
      } else {
        rule.secondColumnCode = undefined;
      }

      const index = rules.value.indexOf(rule);
      if (index === -1) return;

      // Handle value conversion between single and double value operators
      if (rule.operator === 'BETWEEN' || rule.operator === 'PERCENTAGE_DIFFERENCE_BETWEEN') {
        const currentValue = Array.isArray(rule.value) ? rule.value[0] : rule.value;
        rule.value = [currentValue, currentValue];
        if (!betweenValues.value[index]) {
          betweenValues.value[index] = { min: currentValue, max: currentValue };
        } else {
          betweenValues.value[index] = {
            min: currentValue,
            max: currentValue
          };
        }
      } else {
        // Convert from array to single value
        rule.value = Array.isArray(rule.value) ? rule.value[0] : rule.value;
      }
    }

    // Watch for operator changes
    watch(() => rules.value.map(r => r.operator), () => {
      // Ensure betweenValues array matches rules array length
      while (betweenValues.value.length < rules.value.length) {
        betweenValues.value.push({ min: 0, max: 0 });
      }
    });

    // Adds a new color rule with default values
    const addNewRule = () => {
      addRule({
        columnCode: props.columns[0]?.code || '',
        value: 0,
        operator: '=',
        color: '#000000',
        coloringType: 'cell',
        measure: props.measure?.code || '',
        mapData: () => {}
      })
    }

    const getRowStyle = (row: Record<string, any>) => {
      const applicableRules = rules.value.filter(rule => 
        rule.coloringType === 'row' && 
        validateRule(row[rule.columnCode as string], rule)
      )
      
      return applicableRules.length 
        ? { backgroundColor: applicableRules[0].color }
        : {}
    }

    const getCellStyle = (value: any, columnCode: string) => {
      const applicableRules = rules.value.filter(rule =>
        rule.coloringType === 'cell' &&
        rule.columnCode as string === columnCode &&
        validateRule(value, rule)
      )

      return applicableRules.length
        ? { backgroundColor: applicableRules[0].color }
        : {}
    }

    const handleColumnChange = (rule: ColorRule, value: string, index: number) => {
      if (isMultiColumnOperator(rule.operator)) {
        if (index === 0) {
          rule.columnCode = value;
        } else {
          rule.secondColumnCode = value;
        }
      } else {
        rule.columnCode = value;
      }
    }

    const updateBetweenValue = (rule: ColorRule, index: number) => {
      const { min, max } = betweenValues.value[index]
      rule.value = [min, max]
    }

    const predefinedColors = [
      { name: 'Light Red', value: 'rgb(255, 204, 204)' }, 
      { name: 'Light Orange', value: 'rgb(255, 240, 240)' },
      { name: 'Light Yellow', value: 'rgb(255, 255, 240)' }, 
      { name: 'Light Green', value: 'rgb(230, 255, 230)' }, 
      { name: 'Light Purple', value: 'rgb(255, 204, 255)' }
    ]

    const handleDeleteRule = (index: number) => {
      rules.value.splice(index, 1)
      betweenValues.value.splice(index, 1)
    }

    const handleScopeChange = (rule: ColorRule, value: 'row' | 'cell' | 'report') => {
      rule.coloringType = value;
      rule.columnCode = value === 'report' ? 'all_columns' : (props.columns[0]?.code || '');
    }

    const onDrop = (dropResult: any) => {
      const { removedIndex, addedIndex, payload } = dropResult;
      if (removedIndex === null && addedIndex === null) return;
      
      const result = [...rules.value];
      let itemToAdd = payload;
      
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }
      
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      
      rules.value = result;
    };

    return {
      rules,
      addNewRule,
      handleDeleteRule,
      operators,
      isMultiColumnOperator,
      handleOperatorChange,
      getRowStyle,
      getCellStyle,
      isCloning,
      isSaving,
      handleSave: () => emit('save', rules.value),
      handleCancel: () => emit('cancel'),
      handleCloneReport: () => emit('clone-report'),
      handleColumnChange,
      betweenValues,
      updateBetweenValue,
      isDoubleValueOperator,
      predefinedColors,
      handleScopeChange,
      scopeItems,
      onDrop
    }
  }
})
