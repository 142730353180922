
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import ActionButton from '@/components/ActionButton.vue';
import Alert from '@/components/Alert.vue';
import GoogleDriveSelector from '@/components/GoogleDriveSelector.vue';
import PluginConfiguration from '@/components/PluginConfiguration.vue';
import IncomingInventorySpreadSheetSettings from '@/components/IncomingInventorySpreadSheetSettings.vue';
import QuickbooksIntegration from '@/components/QuickbooksIntegration.vue';
import {post, get} from "@/services/http";

@Component({
  components: {
    ActionButton,
    Alert,
    GoogleDriveSelector,
    PluginConfiguration,
    IncomingInventorySpreadSheetSettings,
    QuickbooksIntegration
  },
})
export default class IncomingInventorySettings extends Vue {
  @Prop({default: null})
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public selectSourceLoading: boolean = false;

  public sources: object[] = [
    {
      value: 'shopify',
      text: 'Purchase Orders from Shopify'
    },
    {
      value: 'spreadsheets',
      text: 'Google speardsheets file'
    },
    {
      value: 'stocky',
      text: 'Purchase Orders from app Stocky'
    },
    {
      value: 'quickbooks',
      text: 'Purchase Orders from QuickBooks'
    },
    {
      value: 'not_set_up_yet',
      text: 'Not set up yet'
    }
  ];
  public selectedSource: string = 'not_set_up_yet';

  public async created() {
    try {
      this.loading = true;

      const response: any = await get('/incoming_inventory/source');
      if (response.data.source) {
        this.selectedSource = response.data.source;
      }
      this.loading = false;
      this.success = true;
    } catch (e) {
      // console.log(e);
    }
  }

  public async updateSelectedSource(source: string) {
    await post('/incoming_inventory/source', {source});
  }

}
