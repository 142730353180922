
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ActionButton from '@/components/ActionButton.vue';
import { SpreadsheetTemplateModel } from '@/models/spreadsheet_template';
import { StoreModel } from '@/models/store';
import { SpreadsheetTemplateList } from "@/collections/spreadsheet_templates";
import GoogleDriveSelector from '@/components/GoogleDriveSelector.vue';
import { SpreadsheetConnectedModel } from '@/models/spreadsheet_connected';
import { EventBus } from "@/main";
@Component({
  components: {
    HeaderTitle,
    ActionButton,
    GoogleDriveSelector,
  },
})
export default class SpreadsheetTemplates extends Vue {
  @Prop({ default: null })
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public isLoading = false;
  public spreadsheetTemplates = new SpreadsheetTemplateList();
  public templateHeaders: any = [
    { text: 'Name', value: 'name' },
    { text: 'Description', value: 'description' },
    { text: 'Actions', value: 'actions', sortable: false, width: '200px' },
  ];
  public editingTemplate: SpreadsheetTemplateModel = new SpreadsheetTemplateModel();
  public connectedSheet: SpreadsheetConnectedModel = new SpreadsheetConnectedModel();
  public dialogCloneTemplate: boolean = false;
  public selectedGoogleAccount: string = '';
  public selectedDriveFolder: string = '';
  public cloneTemplateLoading: boolean = false;
  public connectSuccess: boolean = false;
  public userEmail: string = '';
  public connectedSpreadsheetLink: string = '';
  public cloneStatusMessage: string = 'Enter your email to clone the template';
  public incomingSpreadsheetRequests: any = [
    {
      name: 'Demand Forecasting',
      description: 'Predict future sales trends to ensure sufficient stock availability while avoiding overstock or stockouts.',
    },
    {
      name: 'Replenishment from Warehouse',
      description: 'Optimize inventory transfers between warehouse and store locations to maintain stock availability.',
    },
    {
      name: 'Inventory Planning for New Product Launching',
      description: 'Develop inventory strategies for new product launches to meet anticipated demand.',
    },
    {
      name: 'High-Value Item Planning',
      description: 'Strategize inventory management for high-value products to minimize risks and maximize profits.',
    },
    {
      name: 'Inventory Planning for One-Time Promotional Campaign',
      description: 'Plan inventory levels to support short-term promotional campaigns effectively.',
    },
    {
      name: 'Plan Inventory Based on Regional Variations in Demand',
      description: 'Adjust inventory levels to cater to regional differences in product popularity and sales trends.',
    },
    {
      name: 'Plan for Clearance and Overstock Reduction',
      description: 'Develop strategies to clear excess inventory and minimize financial losses.',
    },
    {
      name: 'Plan Inventory for Products Across Different Lifecycle Stages',
      description: 'Tailor inventory strategies based on product lifecycle stages: introduction, growth, maturity, and decline.',
    },
    {
      name: 'Plan Inventory Across Multiple Sales Channels',
      description: 'Forecast and plan inventory needs tailored to various sales channels, such as online stores, marketplaces, wholesale, and retail locations.',
    },
  ];
  public async created() {
    this.isLoading = true;
    await this.spreadsheetTemplates.fetch();
    this.isLoading = false;
  }
  public async cloneTemplate() {
    this.cloneTemplateLoading = true;
    this.cloneStatusMessage = "Cloning the template...";
    this.connectSuccess = false;
    try {
      const newSpreadsheet: any = await this.editingTemplate.clone(this.userEmail, this.selectedDriveFolder);
      this.connectedSpreadsheetLink = newSpreadsheet;
      if (newSpreadsheet) {
        this.cloneStatusMessage = "Creating schedule...";
        const res: any = await this.editingTemplate.createSchedule(newSpreadsheet, this.store.config.timezone);
        if (res.data) {
          this.cloneStatusMessage = "Pushing data to the spreadsheet...";
          const connectedSheet = new SpreadsheetConnectedModel(res.data);
          await connectedSheet.refreshData();
          this.cloneStatusMessage = "Data pushed successfully";
        }
        EventBus.$emit("show-snackbar", 
        {
          message: "The "+ this.editingTemplate.name + "spreadsheet data has been refreshed successfully.",
          color: "success",
        });
        this.connectSuccess = true;
      }
    } catch (e) {
      this.cloneTemplateLoading = false;
      this.connectSuccess = false;
      EventBus.$emit("show-snackbar", 
      {
        message: "Failed to clone the spreadsheet template.",
        color: "error",
      });
    }
    this.cloneTemplateLoading = false;
  }
  public showCloneDialog(item: SpreadsheetTemplateModel) {
    this.editingTemplate = item;
    this.dialogCloneTemplate = true;
  }

  public viewConnectedSpreadsheet() {
    this.$router.push('/spreadsheet-connected');
  }

  public get isPaidUser() {
    const isPaid: boolean = this.store.currentPackage.name !== 'Free';
    return isPaid;
  }
}
