
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { FilterColumnList } from '@/collections/filter_columns';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { ReportModel } from '@/models/report';
import { StoreModel } from '@/models/store';
import { MetricModel } from '@/models/metric';
import { MetricList } from '@/collections/metrics';
import { Container, Draggable } from 'vue-smooth-dnd';
import { DimensionColumnList } from '@/collections/dimension_columns';

import { EventBus } from '@/main';
import ActionButton from '@/components/ActionButton.vue';

@Component({
  components: {
    Container,
    Draggable,
    ActionButton,
  },
})
export default class ColumnSelector extends Vue {
  @Prop({ default: null })
  public value!: any[];
  public columnSelector: boolean = false;
  @Prop({ default: false })
  public columnSelectorMenu!: boolean;
  public isLoadingColumn: boolean = false;
  public report: ReportModel = new ReportModel();
  public filterColumns: FilterColumnList = new FilterColumnList();
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => new MeasureModel() })
  public measure!: MeasureModel;
  @Prop({ default: () => new DimensionModel() })
  public dimension!: DimensionModel;
  public selectedDimension: DimensionModel = new DimensionModel();
  @Prop({ default: () => new AnalyticsTypeModel() })
  public analyticsType!: AnalyticsTypeModel;
  @Prop({ default: '' })
  public pivotOption!: string;
  @Prop({ default: [] })
  public filterTimerange!: string[];
  @Prop({ default: [] })
  public initReportColumns!: any;
  public reportColumns: any = [];
  @Prop({ default: [] })
  public initSelectedReportColumns!: any;
  public selectedReportColumns: any = [];
  public selectingItems: any = [];
  @Prop({ default: () => new DimensionColumnList() })
  public dimensionColumns!: DimensionColumnList;
  public columnGroups: any = [];
  public defaultColumns: any = [];
  public dialogAddDimensionColumn: boolean = false;
  public pivotColumns: any = [];
  public pivotDimensions: any = [];
  public pivotMeasures: any = [];
  public metricSources: any = [];
  public dialogPivotOption: boolean = false;
  public addedPivotMeasure: any = null;
  public addedPivotColumn: any = null;
  public selectedPivotColumns: any = [];
  @Prop({ default: 'column' })
  public currentTab!: string;
  public dialogEditColumnConfig: boolean = false;
  public editingColumnIndex: number = -1;
  public newColumnName: string = '';
  @Prop({ default: 0 })
  public customReportId!: number;
  @Prop({ default: 0 })
  public templateReportId!: number;
  public newCalculatedMetric: MetricModel = new MetricModel();
  @Prop({ default: () => new MetricList() })
  public initCalculatedMetrics!: MetricList;
  public calculatedMetrics: MetricList = new MetricList();
  public metricSource: any = [];
  public dialogDelete: boolean = false;
  public deletingMetric: MetricModel = new MetricModel();
  public activeMetricIndex: number = -1;
  public nameRules: any = [this.isNameExist];
  public metricDataTypes: any = [
    { label: 'Number', value: 'number' },
    { label: 'Percentage', value: 'percentage' },
    { label: 'Text', value: 'text' },
  ];
  public alias: any = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'K',
    'M',
    'N',
    'P',
    'Q',
  ];
  public selectAll: boolean = false;
  public created() {
    this.initSelectingItems();
    EventBus.$on('open-add-more-column-dialog-event', async () => {
      this.dialogAddDimensionColumn = true;
    });
  }
  public isNameExist(v: any) {
    return !(!v && this.newCalculatedMetric.name) || 'Must not be empty';
  }

  public getShortColumnName(columnName: string) {
    const max: number = 70;
    const len = columnName.length;
    let substr = columnName.substring(0, max);
    if (len >= max) {
      substr = substr + ' ...';
    }
    return substr;
  }

  public enableRename(index: number) {
    this.editingColumnIndex = index;
    this.dialogEditColumnConfig = true;
    this.newColumnName = this.selectingItems[index].newName;
  }

  public updateColumnConfig() {
    this.dialogEditColumnConfig = true;
    if (this.editingColumnIndex === -1) {
      return;
    }
    const index = this.editingColumnIndex;
    this.selectingItems[index].newName = this.newColumnName;
    this.newColumnName = '';
    this.editingColumnIndex = -1;
    this.dialogEditColumnConfig = false;
  }

  public massSelectItem() {
    for (const item of this.selectingItems) {
      item.value = this.selectAll;
    }
  }

  public initSelectingItems() {
    this.selectedReportColumns = [];
    for (const item of this.initSelectedReportColumns) {
      this.selectedReportColumns.push(item);
    }

    this.selectingItems = [];
    for (const item of this.selectedReportColumns) {
      this.selectingItems.push({
        column: item,
        value: true,
        newName: item.name,
        sort: '',
      });
    }
    for (const item of this.reportColumns) {
      if (!this.isSelectedColumn(item.code)) {
        this.selectingItems.push({
          column: item,
          value: false,
          newName: item.name,
          sort: '',
        });
      }
    }
  }

  public get canAddPivotOption() {
    if (!this.addedPivotColumn || !this.addedPivotMeasure) {
      return false;
    }
    return this.addedPivotColumn.code && this.addedPivotMeasure.code;
  }

  public getReportColumnByCode(code: string) {
    for (const column of this.reportColumns) {
      if (column.code === code) {
        return column;
      }
    }
    for (const dimension of this.dimensionColumns.items) {
      if (dimension.code === code) {
        return dimension;
      }
    }
    return null;
  }

  public async refreshColumnGroup() {
    if (!this.dimensionColumns || this.dimensionColumns.size() === 0) {
      return;
    }
    const newColumnGroups: any = [];
    for (const group of this.columnGroups) {
      const items = await this.getDimensionColumns(group.value);
      if (items && items.length > 0) {
        group.items = items;
        newColumnGroups.push(group);
      }
    }
    this.columnGroups = newColumnGroups;
    for (const index in this.columnGroups) {
      const group = this.columnGroups[index];
      this.defaultColumns.push({ header: group.text });
      for (const column of group.items) {
        this.defaultColumns.push(column);
      }
      if (parseInt(index) === this.columnGroups.length - 1) {
        this.defaultColumns.push({ divider: true });
      }
    }
  }

  public async getDimensionColumns(group: string) {
    const columns: any = [];
    for (const item of this.dimensionColumns.items) {
      if (
        item.group_name === group &&
        (await this.measure.isSupportedColumn(item.code))
      ) {
        if (!this.isAddedColumn(item.code)) {
          columns.push(item);
        }
      }
    }
    return columns;
  }

  public addColumn(dimensionColumn: any) {
    this.selectingItems.push({
      column: dimensionColumn,
      value: true,
      newName: dimensionColumn.name,
      sort: '',
    });
    this.dialogAddDimensionColumn = false;
    this.saveColumn();
    this.refreshColumnGroup();
    this.columnSelector = true;
    this.$emit('need-refresh-table-data');
  }
  public onDrop(result: any) {
    this.selectingItems = this.applyDrag(this.selectingItems, result);
  }

  public applyDrag(result: any, dragResult: any) {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) {
      return result;
    }
    let itemToAdd = payload;
    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    return result;
  }

  public isSelectedColumn(code: string) {
    if (this.selectingItems && this.selectingItems.length > 0) {
      for (const item of this.selectingItems) {
        if (item.column.code === code && item.value) {
          return true;
        }
      }
    }
    return false;
  }

  public isAddedColumn(code: string) {
    if (!this.selectingItems || this.selectingItems.length === 0) {
      return false;
    }
    if (this.selectingItems && this.selectingItems.length > 0) {
      for (const item of this.selectingItems) {
        if (item.column.code === code) {
          return true;
        }
      }
    }
    return false;
  }

  public async showColumnSelector() {
    this.columnSelector = true;
    this.initSelectingItems();
  }

  public saveColumn() {
    const selectedItems: any = [];
    for (const item of this.selectingItems) {
      if (item.value) {
        const column = {
          name: item.newName,
          code: item.column.code,
          dataType: item.column.dataType,
          luisMapping: item.column.luisMapping,
        };
        selectedItems.push(column);
      }
    }

    this.$emit('input', selectedItems);
    this.$emit('need-refresh-metric-data');

  }

  public saveColumnAndClose() {
    const selectedItems: any = [];
    for (const item of this.selectingItems) {
      if (item.value) {
        const column = {
          name: item.newName,
          code: item.column.code,
          dataType: item.column.dataType,
          luisMapping: item.column.luisMapping,
        };
        selectedItems.push(column);
      }
    }
    EventBus.$emit('apply-visulization-column', selectedItems);
  }

  @Watch('columnSelectorMenu', { immediate: true, deep: true })
  private async onColumnSelectorMenuChange(newVal: any) {
    if (newVal && newVal === true) {
      this.columnSelector = this.columnSelectorMenu;
    }
  }

  @Watch('initReportColumns', { immediate: true, deep: true })
  private async onReportColumnsChange(newVal: any) {
    if (newVal) {
      if (this.initReportColumns && this.initReportColumns.length > 0) {
        this.reportColumns = [];
        for (const item of this.initReportColumns) {
          this.reportColumns.push(item);
        }
        this.initSelectingItems();
      }
    }
  }

  
}
