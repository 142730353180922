
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { MetricModel } from '@/models/metric';

@Component
export default class CalculatedMetrics extends Vue {
  @Prop({ type: Object, required: true }) metric!: MetricModel;
  @Prop({ type: Array, required: true }) metrics!: any[];
  @Prop({ type: Array, required: true }) reportColumns!: any[];
  @Prop({ type: Array, required: true }) dataTypes!: any[];

  private selectedMetric: any = null;
  private metricSources: any[] = [];
  private isEditing = false;
  private activeMetricIndex = -1;
  private showDeleteDialog = false;
  private deletingMetric: any = null;
  private readonly alias = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K', 'M', 'N', 'P', 'Q'
  ];

  private getMetricAlias(index: number): string {
    return this.alias[index] || '';
  }

  created() {
    this.initMetricSources();
  }

  @Watch('reportColumns', { immediate: true, deep: true })
  private onReportColumnsChange() {
    this.initMetricSources();
  }

  private initMetricSources(): void {
    this.metricSources = this.reportColumns
      .filter(item => 
        !item.code.includes('_pivot_') && 
        !['image'].includes(item.dataType) &&
        !['text', 'date', 'datetime'].includes(item.dataType)
      )
      .map((item, index) => ({
        ...item,
        alias: this.getMetricAlias(index)
      }));
  }

  private readonly nameRules = [(v: string) => !!v || 'Name is required'];
  private readonly formulaRules = [
    (v: string) => !!v || 'Formula is required',
    (v: string) => this.isFormulaValid(v) || 'Invalid formula'
  ];

  get isValid(): boolean {
    return !!(
      this.metric.name &&
      this.metric.formula &&
      this.metric.columns.length > 0 &&
      this.isFormulaValid(this.metric.formula)
    );
  }

  private isFormulaValid(formula: string): boolean {
    if (!formula) return true;
    try {
      let testFormula = formula;
      this.metric.columns.forEach((col: any, i: number) => {
        testFormula = testFormula.replaceAll(
          this.getMetricAlias(i),
          (i + 1).toString()
        );
      });
      const result = eval(testFormula);
      return isFinite(result);
    } catch {
      return false;
    }
  }

  private onMetricSelect(): void {
    if (!this.selectedMetric || this.metric.columns.length >= 10) return;
    
    this.metric.columns.push({
      name: this.selectedMetric.name,
      code: this.selectedMetric.code,
    });
    this.selectedMetric = null;
  }

  private removeMetric(index: number): void {
    this.metric.columns.splice(index, 1);
  }

  private createCalculatedMetric(): void {
    if (!this.isValid) return;
    let code = this.metric.name;
    code = code.replace(/[&\/\\#,+()$~%.'" \[\]@:*?<>{}\(\)!]/g, '');
    this.metric.code = code;

    if (this.isEditing) {
      this.metrics[this.activeMetricIndex] = { ...this.metric };
    } else {
      this.metrics.push({ ...this.metric });
    }
    this.$emit('on-metric-change', this.metrics);
    this.resetForm();
  }

  private editMetric(metric: any, index: number): void {
    this.isEditing = true;
    this.activeMetricIndex = index;
    Object.assign(this.metric, metric);
  }

  private confirmDeleteMetric(metric: any, index: number): void {
    this.deletingMetric = metric;
    this.activeMetricIndex = index;
    this.showDeleteDialog = true;
  }

  private deleteMetric(): void {
    this.metrics.splice(this.activeMetricIndex, 1);
    this.$emit('on-metric-change', this.metrics);
    this.showDeleteDialog = false;
    this.activeMetricIndex = -1;
    this.deletingMetric = null;
  }

  private resetForm(): void {
    this.metric.name = '';
    this.metric.formula = '';
    this.metric.dataType = 'number';
    this.metric.columns = [];
    this.isEditing = false;
    this.activeMetricIndex = -1;
  }

  private onCancel(): void {
    this.resetForm();
  }
}
