import { get, post } from '@/services/http';
import { ReportMetadataList } from '@/collections/report_metadatas';
import { ReportMetadataModel } from '@/models/report_metadata';
export class OpenaiModel {
  public async searchByOpenAI(search: string) {
    const templateReports: ReportMetadataList = new ReportMetadataList();
    try {
      const res: any = await get(`/template_reports/search?keyword=${encodeURIComponent(search)}`);
      for (const s of res.data) {
        const templateReport = new ReportMetadataModel();
        templateReport.mapData(s);
        templateReports.items.push(templateReport);
      }
      
    } catch (error: any) {
      if (error.code !== 'ERR_CANCELED') {
        throw(error);
      }
    }
    return templateReports;
  }

  public async searchFAQ(search: string) {
    const res: any = await get(`/faqs/search?keyword=${encodeURIComponent(search)}`);
    const faqs: any = [];
    let count: number = 0;
    for (const faq of res.data) {
      count++;
      if (count <= 5) {
        faqs.push(faq);
      }
    }
    return faqs;
  }

  public async getDataByQuestion(question: string) {
    try {
      const res: any = await get(`/questions/handle?channel=websocket&isRaw=true&skippedRelatedQuestions=true&text=${encodeURIComponent(question)}`);
      return res;
    } catch (error: any) {
      if (error.code !== 'ERR_CANCELED') {
        throw(error);
      }
    }
  }

  public async getRecommendedQuestion(keyword: string) {
    try {
      const res: any = await get(`/questions/recommend?keyword=${encodeURIComponent(keyword)}`);
      return res.data;
    } catch (error: any) {
      if (error.code !== 'ERR_CANCELED') {
        throw(error);
      }
    }
  }
}
