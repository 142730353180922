import { get } from '@/services/http';

export class DiscountModel {
  public id: number;
  public createdAt?: string;
  public updatedAt?: string;
  public code: string;
  public rate: number;
  public limitIntervals: number;
  public activatedAt?: string;
  public status: string;
  public cancelledAt?: string;
  public merchantId: number;
  public kind: string;
  public limitUsageNumber: number;
  public appliedTime?: number;
  public merchantNames?: string[] | undefined;
  public type: string = 'PERCENTAGE_DISCOUNT'; // FIXED_DISCOUNT_AMOUNT, FIXED_PRICE
  public fixedPrice?: number;
  public discountAmount?: number;

  constructor(data: any = {}) {
    this.id = data.id;
    this.code = data.code;
    this.rate = 10;
    this.limitIntervals = 1;
    this.status = 'ACTIVE';
    this.merchantId = data.merchantId;
    this.kind = 'REUSE';
    this.limitUsageNumber = -1;
    this.type = data.type || 'PERCENTAGE_DISCOUNT';
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.code = data.code;
    this.rate = data.rate;
    this.limitIntervals = data.limitIntervals;
    this.activatedAt = data.activatedAt;
    this.status = data.status;
    this.cancelledAt = data.cancelledAt;
    this.merchantId = data.merchantId;
    this.kind = data.kind;
    this.limitUsageNumber = data.limitUsageNumber;
    this.appliedTime = data.appliedTime;
    if (data.merchantNames && data.merchantNames.length > 0) {
      this.merchantNames = data.merchantNames;
    }
    this.type = data.type || 'PERCENTAGE_DISCOUNT';
    this.fixedPrice = data.fixedPrice;
    this.discountAmount = data.discountAmount;
  }
}
