
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import { ReportModel } from '@/models/report';
import { ComparisonDateModel } from '@/models/comparison_date';

interface ColumnData {
  code: string;
  name: string;
  values: any[];
  dataType: string;
  comparison?: {
    last_period: { values: any[]; originalValues: any[] };
    period_over_period: { values: any[]; originalValues: any[] };
    period_over_period_percent: { values: any[] };
    is_positive_trend: boolean;
  };
}

@Component({})
export default class ReportCardBigNumber extends Vue {
  public chart?: any;
  @Prop({default: false})
  public fullWidth!: boolean;
  @Prop({default: false})
  public needReload!: boolean;
  @Prop({default: []})
  public selectedReportColumns!: any;
  public activeColumns: any = [];
  @Prop({default: new ReportModel()})
  public report!: ReportModel;
  @Prop({default: 0})
  public id!: number;
  @Prop({default: () => new ComparisonDateModel()})
  public comparisonDate!: ComparisonDateModel;
  public titleFontSize: string = '14px';
  public valueFontSize: string = 'h3';
  public cardWidth: string = '250px';
  public cardPaddingTop: string = '0px';

  /** Filters active columns to exclude image, text, date, and datetime types */
  get filteredColumns(): ColumnData[] {
    return this.activeColumns.filter(
      (column: ColumnData) => !['image', 'text', 'date', 'datetime'].includes(column.dataType)
    );
  }

  /** Returns card styling object with padding-top property */
  get cardStyle() {
    return {
      'padding-top': this.cardPaddingTop
    };
  }

  /** 
   * Reloads the component data:
   * - Updates active columns based on selection
   * - Processes and filters columns
   * - Updates metric sizing
   * - Emits reload completion event
   */
  public async reload() {
    this.activeColumns = this.selectedReportColumns?.length 
      ? this.selectedReportColumns 
      : this.report.columns;

    // Process filtered columns to create a new array of report columns:
    // 1. Take a slice of filteredColumns up to the length of report.columns 
    // 2. Map each column through getReportColumnData() to get the matching report column data
    // 3. Filter out any undefined results to ensure valid columns only
    // 4. Use type assertion to ensure TypeScript recognizes valid report columns
    const newColumns = this.filteredColumns
      .slice(0, this.report.columns.length)
      .map(column => this.getReportColumnData(column))
      .filter((column): column is typeof this.report.columns[0] => column !== undefined);

    this.activeColumns = newColumns;
    this.report.columns = newColumns;

    this.updateMetricSizing(newColumns.length);
    this.$emit('reload-completed', 'BIG_NUMBER');
  }

  /** 
   * Updates card width and font size based on number of metrics
   * @param metricCount - Number of metrics to display
   */
  private updateMetricSizing(metricCount: number) {
    this.cardWidth = `${100 * (1 / metricCount)}%`;
    this.valueFontSize = metricCount === 1 ? 'h3' : metricCount === 2 ? 'h4' : 'h5';
  }

  /**
   * Retrieves report column data for given active column
   * @param activeColumn - Column to find data for
   * @returns Matching report column data or undefined
   */
  public getReportColumnData(activeColumn: any) {
    for (const item of this.report.columns) {
      if (item.code === activeColumn.code) {
        item.name = activeColumn.name;
        return item;
      }
    }
  }

  /**
   * Gets selected column name or falls back to default name
   * @param column - Column to get name for
   * @returns Selected or default column name
   */
  public getSelectedColumnName(column: any) {
    for (const item of this.selectedReportColumns) {
      if (item.code === column.code) {
        return item.name;
      }
    }
    return column.name;
  }

  /**
   * Gets period-over-period percentage comparison value
   * @param column - Column to get comparison for
   * @returns Comparison percentage value
   */
  public getComparisonValue(column: any) {
    if (column.comparison) {
      return column.comparison.period_over_period_percent.values[0];
    }
  }

  /**
   * Determines if comparison percentage should be shown
   * @param column - Column to check
   * @returns Boolean indicating if comparison should be shown
   */
  public showComparePercentage(column: ColumnData): boolean {
    const { comparison } = column;
    if (!comparison) return false;
    
    const value = Number(comparison.period_over_period.originalValues[0]);
    return value !== 0 && value !== null;
  }

  /**
   * Gets the last period value for comparison
   * @param column - Column to get last period value for
   * @returns Last period value
   */
  public getLastPeriodValue(column: any) {
    if (column.comparison) {
      return column.comparison.last_period.values[0];
    }
  }

  /**
   * Determines if metric shows an increase based on trend direction
   * @param column - Column to check
   * @returns Boolean indicating if metric increased
   */
  public isIncrease(column: ColumnData): boolean {
    const { comparison } = column;
    if (!comparison) return false;

    const periodOverPeriod = Number(comparison.period_over_period.originalValues[0]);
    return comparison.is_positive_trend ? periodOverPeriod >= 0 : periodOverPeriod < 0;
  }

  /**
   * Gets column size based on screen size and layout
   * @param screen - Screen size identifier
   * @returns Column size value
   */
  public getColumnSize(screen: string) {
    let columnCount = this.report.columns.length;
    if (columnCount === 0) {
      columnCount = 1;
    }
    if (columnCount <= 2) {
      return 12 / columnCount;
    }
    switch (screen) {
      case 'xs': {
        return 12;
        break;
      }
      case 'sm': {
        return 12;
        break;
      }
      case 'md': {
        if (this.fullWidth) {
          return 12 / columnCount;
        } else {
          return 6 / columnCount;
        }
        break;
      }
      case 'lg': {
        if (this.fullWidth) {
          return 12 / columnCount;
        } else {
          return 6 / columnCount;
        }
        break;
      }
    }
    return 12;
  }

  /**
   * Checks if column is selected
   * @param code - Column code to check
   * @returns Boolean indicating if column is selected
   */
  public isSelectedColumn(code: string) {
    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      return true;
    }
    for (const c of this.selectedReportColumns) {
      if (c.code === code) {
        return true;
      }
    }
    return false;
  }

  /**
   * Formats the big number value for display
   * @param column - Column containing the value
   * @returns Formatted value or 0 if empty
   */
  public formatBigNumberValue(column: any) {
    if (column.values.length === 0) {
      return 0;
    }
    return column.values[0];
  }

  /**
   * Watches needReload prop and triggers reload when true
   * @param newVal - New value of needReload
   * @param oldVal - Previous value of needReload
   */
  @Watch('needReload', { immediate: true, deep: true  })
  private async onNeedReloadTableChanged(newVal: any, oldVal: any) {
    if (newVal === true) {
      await this.reload();
    }
  }
}
