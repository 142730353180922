
import { Component, Vue, Prop } from 'vue-property-decorator';
import { EventBus } from '@/main';

@Component
export default class PivotOptions extends Vue {
  @Prop({ type: Array, required: true }) reportColumns!: any[];
  @Prop({ type: Array, required: true }) dimensionColumns!: any[];
  @Prop({ type: String, default: '' }) pivotOption!: string;
  @Prop({ type: Function, required: true }) onPivotChange!: (option: string) => void;

  private selectedMeasure: any = null;
  private selectedColumn: any = null;
  private autoSelect = false;
  private measureError = '';
  private columnError = '';
  private pivotColumns: any[] = [];
  private pivotDimensions: any[] = [];
  private pivotMeasures: any[] = [];

  created() {
    this.initPivotColumns();
    this.initPivotData();
  }

  get isValid(): boolean {
    return !!(this.selectedMeasure && this.selectedColumn);
  }

  private initPivotData(): void {
    this.pivotDimensions = [];
    this.pivotMeasures = [];

    for (const item of this.reportColumns) {
      if (item.code.indexOf('_pivot_') === -1) {
        if (['image'].includes(item.dataType)) continue;
        
        if (['text', 'date', 'datetime'].includes(item.dataType)) {
          this.pivotDimensions.push(item);
        } else {
          this.pivotMeasures.push(item);
        }
      }
    }

    // Add dimension columns
    for (const item of this.dimensionColumns) {
      if (['image'].includes(item.data_type)) continue;
      
      if (['text', 'date', 'datetime'].includes(item.data_type)) {
        this.pivotDimensions.push(item);
      } else {
        this.pivotMeasures.push(item);
      }
    }
  }

  private initPivotColumns(): void {
    this.pivotColumns = [];
    if (!this.pivotOption) return;

    try {
      this.initPivotColumnFromJson();
    } catch {
      this.initPivotColumnFromString();
    }
  }

  private initPivotColumnFromJson(): void {
    const pivotItems = JSON.parse(this.pivotOption);
    for (const item of pivotItems) {
      const columnValue = this.getColumnByCode(item.column.code);
      const measureValue = this.getColumnByCode(item.measure.code);
      if (columnValue && measureValue) {
        this.pivotColumns.push({
          column: columnValue,
          measure: measureValue,
          autoSelect: item.autoSelect,
        });
      }
    }
  }

  private initPivotColumnFromString(): void {
    const pivotItems = this.pivotOption.split('_pivot_');
    for (const item of pivotItems) {
      const options = item.split('_cols_');
      if (options.length >= 2) {
        const columnCode = options[0].replace('_rows_', '').replace('+', ' ');
        const measureCode = options[1].replace('+', ' ');
        const columnValue = this.getColumnByCode(columnCode);
        const measureValue = this.getColumnByCode(measureCode);
        if (columnValue && measureValue) {
          this.pivotColumns.push({
            column: columnValue,
            measure: measureValue,
            autoSelect: false,
          });
        }
      }
    }
  }

  private getColumnByCode(code: string): any {
    return this.reportColumns.find(col => col.code === code) ||
           this.dimensionColumns.find(col => col.code === code) ||
           null;
  }

  private onAdd(): void {
    if (!this.isValid) {
      this.validateInputs();
      return;
    }

    this.pivotColumns.push({
      column: this.selectedColumn,
      measure: this.selectedMeasure,
      autoSelect: this.autoSelect
    });

    this.onPivotChange(JSON.stringify(this.pivotColumns));
    this.resetForm();
  }

  private onDelete(option: any): void {
    this.pivotColumns = this.pivotColumns.filter(item => 
      item.column.code !== option.column.code || 
      item.measure.code !== option.measure.code
    );
    
    this.onPivotChange(JSON.stringify(this.pivotColumns));
  }

  private validateInputs(): void {
    this.measureError = !this.selectedMeasure ? 'Please select a metric' : '';
    this.columnError = !this.selectedColumn ? 'Please select a column' : '';
  }

  private resetForm(): void {
    this.selectedMeasure = null;
    this.selectedColumn = null;
    this.autoSelect = false;
    this.measureError = '';
    this.columnError = '';
  }

  private getPivotKey(item: any): string {
    return `${item.column.code}${item.measure.code}`;
  }
}
