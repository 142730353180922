
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ScheduleModel } from "@/models/schedule";
import { ScheduleProfileModel } from "@/models/schedule_profile";
import { ScheduleProfileList } from "@/collections/schedule_profiles";
import { EventBus } from "@/main";
import ActionButton from "@/components/ActionButton.vue";
import { StoreModel } from "@/models/store";

@Component({
  components: {
    ActionButton,
  },
})
export default class ReportScheduleManager extends Vue {
  @Prop({ type: Boolean, default: false }) show!: boolean;
  @Prop({ required: true }) reportData!: any;
  @Prop({ required: true }) store!: StoreModel;
  @Prop({ type: Boolean, default: false }) isEditMode!: boolean;
  @Prop({ type: Object, default: () => new ScheduleModel() }) existingSchedule!: ScheduleModel;
  @Prop({ type: Number, default: 0 }) reportIndex!: number;

  private schedule: ScheduleModel = this.existingSchedule || new ScheduleModel();
  private scheduleProfiles: ScheduleProfileList = new ScheduleProfileList();
  private selectedScheduleProfile: ScheduleProfileModel = new ScheduleProfileModel();
  private isLoadingScheduleProfiles: boolean = false;
  private loading: boolean = false;
  private error: string = "";

  /**
   * Initializes component when mounted
   * Loads schedule data and profiles based on mode
   */
  public async mounted() {
    if (this.isEditMode) {
      this.schedule = this.existingSchedule || new ScheduleModel();
      await this.loadCurrentProfile();
    } else {
      this.initializeSchedule();
      await this.loadScheduleProfiles();
    }
  }

  /**
   * Initializes a new schedule with basic data
   * Sets title from report data
   */
  private initializeSchedule() {
    this.resetState();
    this.schedule.title = this.reportData.title;
  }

  /**
   * Loads available schedule profiles from API
   * Adds default profile to list
   */
  private async loadScheduleProfiles() {
    this.isLoadingScheduleProfiles = true;
    try {
      await this.scheduleProfiles.fetch();
      const defaultProfile = this.getDefaultScheduleProfile();
      this.scheduleProfiles.items.push(defaultProfile);
    } catch (e) {
      EventBus.$emit("show-snackbar", "Failed to load schedule profiles");
    } finally {
      this.isLoadingScheduleProfiles = false;
    }
  }

  /**
   * Creates default schedule profile with basic settings
   * @returns {ScheduleProfileModel} Default profile configuration
   */
  private getDefaultScheduleProfile(): ScheduleProfileModel {
    const profile = new ScheduleProfileModel();
    profile.name = "Create new schedule profile";
    profile.outputType = "email";
    profile.destination = this.store.config.email;
    profile.fileFormat = "csv";
    profile.interval = "daily";
    profile.timezone = this.store.config.timezone;
    profile.deliveryDate = [];
    profile.deliveryWeekday = [];
    profile.deliveryHour = ["09"];
    profile.emails = [this.store.config.email];
    return profile;
  }

  /**
   * Loads current profile for edit mode
   * Fetches profiles and finds matching one
   */
  private async loadCurrentProfile() {
    try {
      this.isLoadingScheduleProfiles = true;
      this.scheduleProfiles = new ScheduleProfileList();
      await this.scheduleProfiles.fetch();
      this.selectedScheduleProfile = this.scheduleProfiles.items.find(
        profile => profile.id === this.schedule.profileId
      ) || new ScheduleProfileModel();
    } catch (e) {
      EventBus.$emit("show-snackbar", "Failed to load schedule profile");
    } finally {
      this.isLoadingScheduleProfiles = false;
    }
  }

  /**
   * Handles schedule creation/update
   * Routes to appropriate action based on mode
   */
  public async createSchedule() {
    if (this.isEditMode) {
      await this.updateSchedule();
      return;
    }
    const profile = this.selectedScheduleProfile || this.getDefaultScheduleProfile();
    if (!this.validateSchedule(profile)) {
      return;
    }

    this.loading = true;
    try {
      await this.setupScheduleWithProfile(profile);
      
      if (!profile.id) {
        this.redirectToScheduleCreate();
      } else {
        await this.schedule.save();
        const scheduleId = this.schedule.id;
        this.close();
        this.$router.push(`/schedule/${scheduleId}`);
        EventBus.$emit("show-snackbar", "Schedule created successfully");
      }
    } catch (e) {
      this.error = "Failed to create schedule";
      EventBus.$emit("show-snackbar", "Failed to create schedule");
    } finally {
      this.loading = false;
    }
  }

  /**
   * Validates schedule configuration
   * @param profile Schedule profile to validate
   * @returns {boolean} Validation result
   */
  private validateSchedule(profile: ScheduleProfileModel): boolean {
    this.error = "";
    if (
      !this.schedule.title?.trim() ||
      !profile.interval ||
      !profile.fileFormat ||
      !profile.outputType ||
      !profile.destination
    ) {
      this.error = "Please name your schedule and select a schedule profile";
      return false;
    }
    return true;
  }

  /**
   * Configures schedule with profile settings
   * @param profile Profile to apply to schedule
   */
  private setupScheduleWithProfile(profile: ScheduleProfileModel) {
    this.schedule.options = [this.getScheduleData()];
    this.schedule.outputType = profile.outputType;
    this.schedule.destination = profile.destination;
    this.schedule.fileFormat = profile.fileFormat;
    this.schedule.interval = profile.interval;
    this.schedule.timezone = profile.timezone;
    this.schedule.deliveryDate = profile.deliveryDate;
    this.schedule.deliveryWeekday = profile.deliveryWeekday;
    this.schedule.deliveryHour = profile.deliveryHour;
    this.schedule.emails = profile.emails;
    this.schedule.profileId = profile.id;

    if (profile.receivers?.items.length > 0) {
      this.schedule.receivers = profile.receivers;
    }
    if (profile.emailTemplate?.id) {
      this.schedule.emailTemplate = profile.emailTemplate;
    }
  }

  /**
   * Prepares schedule data from report configuration
   * @returns Schedule data object
   */
  public getScheduleData() {
    const scheduleData: any = {};
    scheduleData.name = this.reportData.title;
    scheduleData.measureCode = this.reportData.measure;
    scheduleData.measureName = this.reportData.measure;
    scheduleData.dimensionCode = this.reportData.dimension;
    scheduleData.dimensionName = this.reportData.dimension;
    scheduleData.analyticsTypeCode = "";
    scheduleData.pivotOption = "";
    scheduleData.timeRange = [];
    scheduleData.filterColumns = [];
    scheduleData.reportParams = [];
    scheduleData.reportColumns = [];
    scheduleData.calculatedMetrics = [];
    scheduleData.sortBy = this.generateSortByParam(
      this.reportData.pageOptions.sortBy,
      this.reportData.pageOptions.sortDesc
    );

    if (this.reportData.analyticsType) {
      scheduleData.analyticsTypeCode = this.reportData.analyticsType;
    }
    if (this.reportData.pivotOption) {
      scheduleData.pivotOption = this.reportData.pivotOption;
    }
    if (this.reportData.defaultRange) {
      scheduleData.timeRange = [this.reportData.defaultRange];
    } else if (this.reportData.dates && this.reportData.dates.length > 2) {
      scheduleData.timeRange = [this.reportData.dates[0], this.reportData.dates[1]];
    }
    if (this.reportData.filterColumns && this.reportData.filterColumns.length > 0) {
      scheduleData.filterColumns = this.reportData.filterColumns;
    }
    if (this.reportData.savedReportColumns && this.reportData.savedReportColumns.length > 0) {
      scheduleData.reportColumns = this.reportData.savedReportColumns;
    }
    if (this.reportData.reportParams && this.reportData.reportParams.length > 0) {
      scheduleData.reportParams = this.reportData.reportParams;
    }
    if (this.reportData.calculatedMetrics && this.reportData.calculatedMetrics.length > 0) {
      scheduleData.calculatedMetrics = this.reportData.calculatedMetrics;
    }
    if (this.reportData.productSegment) {
      scheduleData.productSegmentId = this.reportData.productSegment;
    }
    return scheduleData;
  }

  /**
   * Generates sort parameters for report data
   * @param sortBy Sort field array
   * @param sortDesc Sort direction array
   * @returns Sort configuration array
   */
  public generateSortByParam(sortBy: any = [], sortDesc: any = []) {
    if (!sortBy || sortBy.length === 0) {
      return [];
    }
    let index: number = 0;
    const sortByResult: any = [];
    for (let index = 0; index < sortBy.length; index++) {
      sortByResult.push({
        key: sortBy[index],
        order: sortDesc[index],
      });
    }

    return sortByResult;
  }

  /**
   * Redirects to schedule creation page
   * Stores schedule data in localStorage
   */
  private redirectToScheduleCreate() {
    localStorage.setItem("schedule_to_create", JSON.stringify(this.schedule));
    this.$router.push({ name: "scheduleCreate" });
  }

  /**
   * Navigates to schedule detail view
   */
  public viewSchedule() {
    if (this.schedule.id) {
      this.$router.push(`/schedule/${this.schedule.id}`);
    }
  }

  /**
   * Closes the dialog and resets state
   */
  public close() {
    this.$emit("update:show", false);
    this.resetState();
  }

  /**
   * Resets component state to initial values
   */
  private resetState() {
    this.schedule = new ScheduleModel();
    this.selectedScheduleProfile = new ScheduleProfileModel();
    this.error = "";
    this.loading = false;
  }

  /**
   * Converts operator code to human readable name
   * @param operator Operator code
   * @returns {string} Human readable operator name
   */
  public operatorName(operator: string): string {
    const operatorMap: { [key: string]: string } = {
      eq: "=",
      gt: ">",
      lt: "<",
      gte: ">=",
      lte: "<=",
      in: "in",
      not_in: "not in",
      between: "between",
      like: "contains"
    };
    return operatorMap[operator] || operator;
  }

  /**
   * Converts array of filter values to string
   * @param values Array of filter values
   * @returns {string} Comma separated string of values
   */
  public filterValuesToString(values: any[]): string {
    return values?.join(", ") || "";
  }

  /**
   * Updates existing schedule with new data
   * Handles save and navigation
   */
  private async updateSchedule() {
    try {
      this.loading = true;
      const scheduleData = this.getScheduleData();
      this.schedule.options[this.reportIndex] = scheduleData;
      const scheduleId = this.schedule.id;
      await this.schedule.update();
      EventBus.$emit("show-snackbar", "Schedule updated successfully");
      this.close();
      this.$router.push(`/schedule/${scheduleId}`);
    } catch (e) {
      this.error = "Failed to update schedule";
      EventBus.$emit("show-snackbar", "Failed to update schedule");
    } finally {
      this.loading = false;
    }
  }
}
