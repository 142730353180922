
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Container, Draggable } from 'vue-smooth-dnd';

@Component({
  components: {
    Container,
    Draggable
  }
})
export default class DraggableColumnList extends Vue {
  @Prop({ type: Array, required: true }) items!: any[];
  @Prop({ type: Boolean, default: false }) isCreatingView!: boolean;

  get containerStyle(): string {
    const height = this.isCreatingView ? 
      'calc(100vh - 400px)' : 
      'calc(100vh - 280px)';
    return `overflow-y: scroll; height: ${height};`;
  }

  private getShortColumnName(name: string): string {
    const maxLength = 70;
    if (name.length <= maxLength) return name;
    return `${name.substring(0, maxLength)}...`;
  }

  private onDrop(dropResult: any): void {
    this.$emit('drop', dropResult);
  }

  private onRename(index: number): void {
    this.$emit('rename', index);
  }
}
