export const mockColorRules = {
  rules: [
    
    {
      columnCode: "[stock_days_inventory_planning]",
      value: 7, // Critical level - Less than 7 days of inventory
      operator: "<",
      color: "rgb(255, 204, 204)", // Even lighter red
      measure: "inventory_replenishment", 
      coloringType: "row",
    },
    {
      columnCode: "[stock_days_inventory_planning]",
      value: [7, 14], // Warning level - Between 7-14 days
      operator: "BETWEEN",
      color: "rgb(255, 240, 240)", // Even lighter orange
      measure: "inventory_replenishment",
      coloringType: "row",
    },
    {
      columnCode: "[stock_days_inventory_planning]",
      value: [14, 30], // Healthy level - Between 14-30 days
      operator: "BETWEEN", 
      color: "rgb(230, 255, 230)", // Even lighter green
      measure: "inventory_replenishment",
      coloringType: "row",
    },
    {
      columnCode: "[stock_days_inventory_planning]",
      value: 30, // Excess inventory - More than 30 days
      operator: ">",
      color: "rgb(255, 204, 255)", // Even lighter purple
      measure: "inventory_replenishment",
      coloringType: "row",
    },
    {
      columnCode: "[Actual Inventory Quantity]",
      value: 1,
      operator: "<",
      color: "rgb(255, 204, 204)", // Even lighter red
      measure: "Actual Inventory Quantity",
      coloringType: "row",
    },
    {
        columnCode: "[Actual Inventory Quantity]",
        value: [1, 10],
        operator: "BETWEEN",
        color: "rgb(255, 240, 240)", // Even lighter orange
        measure: "Actual Inventory Quantity",
        coloringType: "row",
      },
    
    // rule for inventory_burndown
    {
      columnCode: "all_columns",
      value: 1,
      operator: "<",
      color: "rgb(255, 204, 204)", // Even lighter red
      measure: "inventory_burndown",
      coloringType: "report",
    },
    
    {
      columnCode: "[Actual Inventory Quantity]",
      secondColumnCode: "[forecast_sales_quantity]",
      value: [0, 20],
      operator: "PERCENTAGE_DIFFERENCE_BETWEEN",
      color: "rgb(230, 255, 230)", // Even lighter green
      measure: "inventory_forecasting",
      coloringType: "row",
    },
    {
        columnCode: "[Actual Inventory Quantity]",
        secondColumnCode: "[forecast_sales_quantity]",
        value: 20, 
        operator: "GREATER_AND_DIFF_PERCENT_GREATER", 
        color: "rgb(255, 240, 240)", // Even lighter orange
        measure: "inventory_forecasting",
        coloringType: "row",
    },
    {
        columnCode: "[forecast_sales_quantity]",
        secondColumnCode: "[Actual Inventory Quantity]",
        value: 20, 
        operator: "GREATER_AND_DIFF_PERCENT_GREATER", 
        color: "rgb(255, 204, 204)", // Even lighter red
        measure: "inventory_forecasting",
        coloringType: "row",
    },
    // rule for lost_revenue
    {
        columnCode: "[lost_sales_quantity]",
        value: 100,
        operator: ">",
        color: "rgb(255, 204, 204)", // Even lighter red
        measure: "lost_revenue",
        coloringType: "row",
    },
    {
        columnCode: "[lost_sales_quantity]",
        value: [10, 100],
        operator: "BETWEEN",
        color: "rgb(255, 240, 240)", // Even lighter orange
        measure: "lost_revenue",
        coloringType: "row",
    },
    {
        columnCode: "[lost_sales_quantity]",
        value: 10,
        operator: "<",
        color: "rgb(255, 255, 240)", // Even lighter yellow
        measure: "lost_revenue",
        coloringType: "row",
    },
    // rule for sell_through_rate based on industry standards
    {
        columnCode: "[sell_through_rate]",
        value: 30,
        operator: "<",
        color: "rgb(255, 204, 204)", // Even lighter red
        measure: "sell_through_rate", 
        coloringType: "row",
    },
    {
        columnCode: "[sell_through_rate]",
        value: [30, 70],
        operator: "BETWEEN",
        color: "rgb(255, 240, 240)", // Even lighter orange
        measure: "sell_through_rate",
        coloringType: "row",
    },
    {
        columnCode: "[sell_through_rate]",
        value: 70,
        operator: ">", 
        color: "rgb(230, 255, 230)", // Even lighter green
        measure: "sell_through_rate",
        coloringType: "row",
    },
    // rule for inventory_turnover based on retail industry standards
    {
        columnCode: "[inventory_turnover]",
        value: 2,
        operator: "<",
        color: "rgb(255, 204, 204)", // Even lighter red
        measure: "inventory_turnover",
        coloringType: "row", 
    },
    {
        columnCode: "[inventory_turnover]",
        value: [2, 4],
        operator: "BETWEEN",
        color: "rgb(255, 240, 240)", // Even lighter orange
        measure: "inventory_turnover",
        coloringType: "row",
    },
    {
        columnCode: "[inventory_turnover]",
        value: 4,
        operator: ">",
        color: "rgb(230, 255, 230)", // Even lighter green
        measure: "inventory_turnover", 
        coloringType: "row",
    },
    // rule for gross_margin based on retail standards
    {
        columnCode: "[Profit Margin %]",
        value: 20,
        operator: "<",
        color: "rgb(255, 204, 204)", // Even lighter red
        measure: "profit_margin",
        coloringType: "row",
    },
    {
        columnCode: "[Profit Margin %]",
        value: [20, 50],
        operator: "BETWEEN", 
        color: "rgb(255, 240, 240)", // Even lighter orange
        measure: "profit_margin",
        coloringType: "row",
    },
    {
        columnCode: "[Profit Margin %]",
        value: 50,
        operator: ">",
        color: "rgb(230, 255, 230)", // Even lighter green
        measure: "profit_margin",
        coloringType: "row",
    },
  ]
}; 