
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CustomReportModel } from '@/models/custom_report';

@Component
export default class ReportActionMenu extends Vue {
  @Prop({ required: true }) expandedSections!: {
    filters: boolean;
    settings: boolean;
    actions: boolean;
    customize: boolean;
  };

  @Prop({ default: () => new CustomReportModel() }) customReport!: CustomReportModel;
  @Prop({ type: Boolean, default: false }) canSaveProductSegment!: boolean;
  @Prop({ default: null }) schedule!: any;
  @Prop({ type: Boolean, default: false }) isAssistyAdmin!: boolean;
}
